import React from "react";
import { Select } from "antd";
import { useQuery } from "react-query";

import { LookUpApiService } from "../../../ApiServices";
import notificationService from "../../../util/notification.service";
import { API_STORAGE_KEY } from "./../../../constants/constant";

const getNeighborhoods = async () => {
    const { ok, data, response } = await LookUpApiService.getNeighborhoods();
    if (ok) {
        return data;
    } else {
        notificationService.error(response.message);
        throw new Error(response.message);
    }
};

const NeighborhoodLookup = (props) => {
    const { isCustomizeWidth = true } = props;

    const { data: neighborhoods = [], isLoading } = useQuery(API_STORAGE_KEY.NEIGHBORHOOD_LOOKUP, getNeighborhoods);

    return (
        <Select
            {...props}
            showSearch
            filterOption={(input, option) => {
                return option.props.children.toLowerCase().includes(input.toLowerCase());
            }}
            style={{
                ...(isCustomizeWidth && {
                    width: "150px",
                    // overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis"
                })
            }}
            allowClear
            loading={isLoading}
            disabled={isLoading}
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
        >
            {neighborhoods?.map((data) => {
                return (
                    <Select.Option key={data?.id} value={data.id}>
                        {data?.name}
                    </Select.Option>
                );
            })}
        </Select>
    );
};
export default NeighborhoodLookup;
