import React from "react";
import { Card, Col, Carousel, Space } from "antd";

import { TextIconWrapper } from ".";
import { sectionContainerType, subChildType } from "../types";
import { ReactComponent as Clock } from "../../../../assets/images/clock-icon.svg";
import { ReactComponent as UserIcon } from "../../../../assets/images/user-icon.svg";
import { CHILDREN_ACTIVITY_TIME_FRAME, CHILDREN_ACTIVITY_TYPE } from "../../../../constants/constant";

import "../index.less";

const SectionsContainer = ({ item, sortList }: sectionContainerType) => {
    return (
        <Col className="section-container">
            <p className="text-brand-primary-900 gx-m-0 gx-pb-3 gx-font-weight-medium gx-fs-lg">{item?.title}</p>
            <Carousel className="gx-mb-3 carousel-dot" dots={true} infinite={false} draggable>
                {item?.subChildrenActivities?.map((subChild: subChildType) => {
                    return (
                        <Card className="gx-m-0 card-styling" bordered={false}>
                            <p className="text-brand-primary-900 gx-m-0 gx-pb-2 gx-font-weight-medium gx-fs-lg">
                                {subChild?.title}
                            </p>
                            <div className="gx-mt-2">
                                <Col className="gx-pl-0 gx-pr-0 img-container" span={24}>
                                    <img
                                        src={subChild?.media?.viewableLink}
                                        className="card-img"
                                        style={{ objectFit: "cover" }}
                                    />
                                </Col>
                                <Col span={24} className="gx-pl-0 gx-pt-3">
                                    <Space size="small" direction="vertical">
                                        <TextIconWrapper
                                            Icon={CHILDREN_ACTIVITY_TYPE[subChild?.type - 1]?.icon}
                                            text={CHILDREN_ACTIVITY_TYPE[subChild?.type - 1]?.label}
                                        />
                                        <TextIconWrapper
                                            Icon={Clock}
                                            text={CHILDREN_ACTIVITY_TIME_FRAME[subChild?.timeFrame -1]?.label}
                                        />
                                        <TextIconWrapper
                                            Icon={UserIcon}
                                            text={subChild?.ageGroups
                                                ?.sort(sortList)
                                                ?.map(
                                                    (item: { description: string }, index: number) =>
                                                        `${item.description} ${
                                                            subChild?.ageGroups?.length - 1 !== index ? ", " : ""
                                                        }`
                                                )}
                                        />
                                    </Space>
                                </Col>
                            </div>
                        </Card>
                    );
                })}
            </Carousel>
        </Col>
    );
};

export default SectionsContainer;
