import { Select } from "antd";
import React from "react";
import { CONTENT_LIST } from "../../../constants/constant";

const ContentLookup = (props) => {
    return (
        <>
            <Select {...props} getPopupContainer={(triggerNode) => triggerNode.parentElement} allowClear>
                {CONTENT_LIST?.map((data) => {
                    return (
                        <Select.Option key={data?.id} value={data.id}>
                            {data?.name}
                        </Select.Option>
                    );
                })}
            </Select>
        </>
    );
};
export default ContentLookup;
