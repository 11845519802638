import ApiService from "../util/api.service";
import utilService from "../util/utils.service";
import { SERVICE_CONFIG_URLS } from "./config";

const NotificationService = {
    addNotification,
    updateNotification,
    deleteNotification,
    getNotificationDetails,
    getNotificationUserCount
};

async function addNotification(payload) {
    const response = await ApiService.post(SERVICE_CONFIG_URLS.NOTIFICATION.ADD_NOTIFICATION, payload);
    return response;
}

async function updateNotification(id, payload) {
    const url = utilService.createDynamicUrl(SERVICE_CONFIG_URLS.NOTIFICATION.UPDATE_NOTIFICATION, id);
    const response = await ApiService.put(url, payload);
    return response;
}

async function deleteNotification(id) {
    const url = utilService.createDynamicUrl(SERVICE_CONFIG_URLS.NOTIFICATION.DELETE_NOTIFICATION, { id });
    const response = await ApiService.delete(url);
    return response;
}

async function getNotificationUserCount(payload) {
    const response = await ApiService.post(SERVICE_CONFIG_URLS.NOTIFICATION.NOTIFICATION_USER_COUNT, payload);
    return response;
}

async function getNotificationDetails(id) {
    const url = utilService.createDynamicUrl(SERVICE_CONFIG_URLS.NOTIFICATION.NOTIFICATION_DETAILS, id);
    const response = await ApiService.get(url);
    return response;
}

export default NotificationService;
