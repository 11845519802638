import ApiService from "../util/api.service";
import { multiFormDataHeader, SERVICE_CONFIG_URLS } from "./config";

const MediaService = {
    uploadLogo,
    uploadMedia,
    duplicateMedia,
    uploadResource,
    uploadProfilePicture,
    removeProfilePicture
};

async function uploadProfilePicture(payload) {
    const response = await ApiService.post(SERVICE_CONFIG_URLS.MEDIA.UPLOAD_PROFILE, payload, {
        ...multiFormDataHeader
    });
    return response;
}

async function uploadMedia(payload) {
    const response = await ApiService.post(SERVICE_CONFIG_URLS.MEDIA.UPLOAD_MEDIA, payload, {
        ...multiFormDataHeader
    });
    return response;
}

async function duplicateMedia(payload) {
    const response = await ApiService.post(SERVICE_CONFIG_URLS.MEDIA.DUPLICATE_MEDIA, payload);
    return response;
}

async function uploadResource(payload, key) {
    const response = await ApiService.post(SERVICE_CONFIG_URLS.MEDIA.UPLOAD_RESOURCE, payload, {
        ...multiFormDataHeader
    });
    return key
        ? {
              ...response,
              key
          }
        : response;
}

async function removeProfilePicture() {
    const response = await ApiService.post(SERVICE_CONFIG_URLS.MEDIA.REMOVE_PROFILE_PICTURE);
    return response;
}

async function uploadLogo(payload) {
    const response = await ApiService.post(SERVICE_CONFIG_URLS.MEDIA.UPLOAD_LOGO, payload, {
        ...multiFormDataHeader
    });
    return response;
}

export default MediaService;
