import React from "react";
import { Layout } from "antd";
import { useQuery } from "react-query";

import Routes from "../../routes";
import Topbar from "../Topbar/index";
import Sidebar from "../Sidebar/index";
import { API_STORAGE_KEY } from "./../../constants/constant";
import AdminApiService from "./../../ApiServices/admin.services";
import notificationService from "./../../util/notification.service";
import FullPageLoader from "../../components/FullPageLoader";

const { Content, Footer } = Layout;

const MainApp = (props) => {
    const getUserDetails = async () => {
        const { ok, data, response } = await AdminApiService.getUserDetails();
        if (ok) {
            return data;
        } else {
            notificationService.error(response.message);
            throw new Error(response.message);
        }
    };

    const { data: userDetails, isLoading } = useQuery(API_STORAGE_KEY.USER_DETAILS, getUserDetails, {
        cacheTime: Infinity,
        staleTime: Infinity
    });

    const renderRoutes = () => {
        if (isLoading) {
            return <FullPageLoader />;
        } else {
            return <Routes />;
        }
    };

    return (
        <Layout className="gx-app-layout gx-pb-0">
            <Sidebar />
            <Layout className="gx-pb-0">
                <Content className={`gx-layout-content justify-between`} id="layout">
                    <div>{renderRoutes()}</div>
                    <Footer>
                        <div className="app-footer-left app-footer noirProRegular">
                            Copyright 2022 - Parent Pass®. In collaboration with
                            <span className="gx-text-primary"> Unique Software Development.</span>
                        </div>
                    </Footer>
                </Content>
            </Layout>
        </Layout>
    );
};
export default MainApp;
