import React from "react";
import PropTypes from "prop-types";
import { Col, Pagination, Row, Select, Space, Table } from "antd";

import { SORT_ORDER, SORT_ORDER_VALUE } from "../../constants/constant";

import "./grid-view.less";

type GridType = {
    columns: any;
    data: any;
    sort?: any;
    rowKey?: any;
    [key: string]: any;
};
const GridView: React.FC<GridType> = (props) => {
    const { addMargin = true } = props;
    const { Option } = Select;

    const handleOnChange = (page: any, pageSize: any) => {
        const newOffset = pageSize * (page - 1);
        return props?.onPaginate(newOffset, pageSize);
    };
    const handleOnChangeTable = (pagination: any, filters: any, sort: any) => {
        if (props?.onSort && props?.clearSort) {
            const { onSort, clearSort } = props;
            const { columnKey, order } = sort;
            if (order === SORT_ORDER.ASC) {
                onSort(columnKey, SORT_ORDER_VALUE.ascend);
            } else if (order === SORT_ORDER.DESC) {
                onSort(columnKey, SORT_ORDER_VALUE.descend);
            } else {
                clearSort();
            }
        }
    };

    const handlePageSizeChange = (value: any) => {
        return props?.onPaginate(0, value);
    };

    function itemRender(current: any, type: any, originalElement: any) {
        if (type === "prev") {
            return <a>Previous</a>;
        }
        if (type === "next") {
            return <a>Next</a>;
        }
        return originalElement;
    }
    const countOpt = [10, 20, 30];

    return (
        <div className={props?.tableKey}>
            {!props?.tabKey ? (
                <Row className="space-align-block gx-m-0" justify="space-between" align="middle">
                    <Col>{props.reactFiltersRender && props.reactFiltersRender()}</Col>

                    <Col className={addMargin ? "gx-mb-4" : ""}>
                        <Space direction="vertical" align="center">
                            <Col>
                                <span className="cb-select">
                                    <Space className="noirProRegular">
                                        {"Show"}
                                        <Select
                                            suffixIcon={
                                                <span className="usd-arrow-down font-weight-300 font-size-10 cb-pl-3 cargobarn-off-white " />
                                            }
                                            style={{ border: "#d9d9d9 1px solid", borderRadius: "4px" }}
                                            size="small"
                                            value={props.pageSize}
                                            onChange={handlePageSizeChange}
                                            bordered={false}
                                            dropdownClassName="noirProRegular gx-fs-md"
                                        >
                                            {countOpt.map((item) => {
                                                return (
                                                    <Option value={item} key={item}>
                                                        <p className="font-weight-300 font-size-13">{item}</p>
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                        {"items"}
                                    </Space>
                                </span>
                            </Col>
                        </Space>
                    </Col>
                </Row>
            ) : null}
            <Table
                loading={props?.isFetching}
                className={`gx-table-header-color ant-table-border ${props?.className ? props?.className : ""}`}
                rowKey={props?.rowKey}
                columns={props.columns}
                tableLayout={props.tableLayout ? props.tableLayout : "fixed"}
                dataSource={props?.data}
                pagination={false}
                onChange={handleOnChangeTable}
            />

            {!props?.tabKey && !props?.isLoading ? (
                <Row className="no-margin ant-row-flex gx-m-0 gx-p-0" justify="center" align="middle">
                    <Col sm={24} xs={24} md={12} xl={12} lg={12}>
                        <Pagination
                            itemRender={itemRender}
                            className="table-pagination"
                            defaultCurrent={1}
                            hideOnSinglePage={true}
                            current={props?.currentPage}
                            pageSize={props.pageSize}
                            total={props?.totalCount}
                            showSizeChanger={props.totalCount > 1000}
                            onChange={handleOnChange}
                        />
                    </Col>
                </Row>
            ) : null}
        </div>
    );
};

export default GridView;
