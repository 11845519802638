import { AutoComplete } from "antd";
import debounce from "lodash/debounce";
import React, { useCallback, useState, useEffect } from "react";

import useScript from "../../../custom-hooks/useScript";
import { googleService } from "../../../util/google.service";
import notificationService from "../../../util/notification.service";

const { Option } = AutoComplete;
const GOOGLE_PLACE_SRC =
    "https://maps.googleapis.com/maps/api/js?key=AIzaSyDwpwQNbbqocBQDopln5AglXpPa4xlDqmQ&libraries=places";
const GoogleAddress = (props) => {
    const status = useScript(GOOGLE_PLACE_SRC);
    const { value, onChange, ...rest } = props;
    const [searchText, setSearchText] = useState("");
    const [predictions, setPredictions] = useState([]);
    const [loading, setLoading] = useState(false);

    const autoCompletePlaces = async (input) => {
        setLoading(true);
        const { ok, data, response } = await googleService.autoCompletePlaces(input);
        if (ok) {
            setPredictions(data.predictions);
            setLoading(false);
        } else {
            notificationService.error(response.message);
            setLoading(false);
            throw new Error(response.message);
        }
    };

    const onSearch = async (val) => {
        setSearchText(val);
    };

    useEffect(() => {
        if (searchText && status === "ready") {
            debouncedGetPlacePredictions(searchText);
        }
    }, [searchText]);

    const debouncedGetPlacePredictions = useCallback(debounce(autoCompletePlaces, 500), []);

    const onSelect = async (val, { company } = {}) => {
        return props?.onChange(company);
    };

    return (
        <>
            <AutoComplete
                {...props}
                onSearch={onSearch}
                placeholder={"Type your answer here..."}
                loading={loading}
                onSelect={onSelect}
                // value={typeof props.value !== "string" ? props?.value?.name : props.value}
            >
                {predictions?.map((data, index) => {
                    return (
                        <Option key={index} value={data?.description} selectedPrediction={data}>
                            {data.description}
                        </Option>
                    );
                })}
            </AutoComplete>
        </>
    );
};
export default GoogleAddress;
