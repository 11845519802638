export const UNAUTHENTICATED_ROUTES = {
  LOGIN: "/login",
  RESET_PASSWORD: "/reset-password/:resetToken",
  FORGET_PASSWORD: "/forget-password",
  SET_PASSWORD: "/set-password/:userId",
  ADD_ACTIVITY: "/add-external-activity",
  EVENT_SUCCESS: "/event-submitted",
  GOOGLE_MAP: "/users-map",
};

export const AUTHENTICATED_ROUTES = {
  CHAT: "/chat",
  USERS: "/users",
  TOPICS: "/topics",
  REPORTS: "/reports",
  ACCESS: "/access",
  TOP_FIVE: "/top-five",
  TOP_FIVE_BY_USER: "/top-five-by-user",
  RESOURCES: "/resources",
  DASHBOARD: "/dashboard",
  GOOGLE_MAP: "/users-map",
  MY_PROFILE: "/my-profile",
  ACTIVITIES: "/events",
  EVENTS: "/events",
  ANNOUNCEMENT: "/announcement",
  ADD_ANNOUNCEMENT: "/add-announcement",
  ADMINISTRATORS: "/administrators",
  CHAT_MANAGEMENT: "/chat-management",
  PUSH_MANAGEMENT: "/push-management",
  REPORTED_CONTENT: "/reported-content",
  FREEBIE_CATEGORY: "/freebie-category",
  ADD_NOTIFICATION: "/add-notification",
  RECOMMEND_CONNECT: "/recommend-connect",
  ORGANIZATIONS: "/organizations",
  CONNECT_CATEGORY: "/connect-category",
  REPORTED_FREEBIES: "/reported-freebies",
  ACCESS_CATEGORY: "/access-category",
  UPDATE_NOTIFICATION: "/update-notification",
  REPORTED_EVENTS: "/reported-events",
  ADD_SUGGEST_PARTNER: "/add-suggest-partner",
  UPDATE_INTERNAL_ACTIVITY: "/update-activity",
  ADD_INTERNAL_ACTIVITY: "/add-internal-activity",
  ADD_TOP_FIVE_ACTIVITIES: "/add-top-five-activities",
  VIEW_TOP_FIVE_ACTIVITIES: "/view-top-five-activities",
  UPDATE_TOP_FIVE_ACTIVITIES: "/update-top-five-activities",
  DUBLICATE_INTERNAL_ACTIVITY: "/dublicate-internal-activity",
  UPDATE_SUGGEST_PARTNER: "/update-suggest-partner/:partnerId",
  ACCESS: "/access",
  ACCESS_CATEGORIES: "/access-categories",
  EDUCATION_SUPPORT_CATEGORY: "/education-support-category",
  EDUCATION_SUPPORT: "/education-support",
  EXPLORE: "/explore",
  EXPLORE_CATEGORIES: "/explore-categories",
  ADD_EXPLORE_INFO: "/add-explore-info",
  ARTICLES: "/articles",
  ADD_ARTICLES: "/add-articles",
  TOP_FIVE_ANNOUNCEMENT: "/top-five-announcement",
  ADD_TOP_FIVE_ANNOUNCEMENT: "/add-top-five-announcement",
  UPDATE_TOP_FIVE_ANNOUNCEMENT: "/update-top-five-announcement",
  VIEW_TOP_FIVE_ANNOUNCEMENT: "/view-top-five-announcement",
};
