import ApiService from "../util/api.service";
import utilService from "../util/utils.service";
import { SERVICE_CONFIG_URLS } from "./config";

const PostService = {
    getPostDetail,
    deletePost
};

async function getPostDetail(query, payload) {
    const url = utilService.createDynamicUrl(SERVICE_CONFIG_URLS.POST.GET_POST_DETAILS, query);
    const response = await ApiService.get(url, payload);
    return response;
}

async function deletePost(query, action) {
    const url = utilService.createDynamicUrl(SERVICE_CONFIG_URLS.POST.DELETE_POST, query);
    const response = await ApiService.delete(url, action);
    return response;
}

export default PostService;
