import React from "react";
import { Tabs } from "antd";

const { TabPane } = Tabs;

const CustomTabs = (props) => {
    const { data } = props;
    return (
        <Tabs {...props}>
            {data?.map((info, i) => (
                <TabPane tab={info?.name} key={String(info?.id)} />
            ))}
        </Tabs>
    );
};

export default CustomTabs;
