import { useQuery } from "react-query";
import React, { useState, useEffect } from "react";
import { CloseOutlined, MoreOutlined } from "@ant-design/icons";
import { Drawer, Space, Col, Row, Dropdown, Menu, Carousel, Skeleton, Divider } from "antd";

import EventCard from "./EventCard";
import utilService from "../../../../util/utils.service";
import Bus from "../../../../assets/images/icon-20-bus.svg";
import Clock from "../../../../assets/images/icon-20-time.svg";
import Dollar from "../../../../assets/images/icon-20-dollar.svg";
import Emblem from "../../../../assets/images/icon-20-emblem.svg";
import LinkIcon from "../../../../assets/images/icon-20-link-2.svg";
import Checkmark from "../../../../assets/images/checkmark-2-2.svg";
import Users from "../../../../assets/images/users.svg";
import notificationService from "../../../../util/notification.service";
import ActivityService from "./../../../../ApiServices/activity.service";
import LocationPin from "../../../../assets/images/icon-location-pin.svg";
import LocationGreen from "../../../../assets/images/icon-20-location-pin.svg";
import { ACTIVITY_TYPE, COST_TYPE, RECURRENCE_TYPE } from "../../../../constants/constantTypes";
import { getDateDifference, formatDateToSpecificTZ, DATE_TIME_FORMAT } from "../../../../util/date-time.service";
import {
    API_STORAGE_KEY,
    EVENT_RECURRENCE_TYPE,
    DAYS_OF_WEEK,
    TRANSPORTATION_TYPE_ID,
    LANGUAGE_ID
} from "../../../../constants/constant";

import "./index.less";
import { renderAction } from "../helper";

const DATE_FORMAT_1 = "dddd, MMMM DD, ";
const MAP_EVENT_TYPE = {
    [ACTIVITY_TYPE.EVENT]: "Event",
    [ACTIVITY_TYPE.PROGRAM]: "Program"
};

const ViewActivity = ({
    visible,
    onClose,
    selectedActivity,
    isReported = false,
    onActionClick,
    showActivityModal,
    fromSearchActivity = false
}) => {
    const [currentActivity, setCurrentActivity] = useState();
    const [reportData, setReportData] = useState();

    const {
        data: activityDetails,
        isFetching: getActivityCallInProgress,
        refetch: refetchActivity
    } = useQuery(
        [API_STORAGE_KEY.VIEW_ACTIVITY, currentActivity?.activityDayId, currentActivity?.languageId],
        async () => {
            const { data, ok, response } = await ActivityService.getActivity({
                activityDayId: currentActivity.activityDayId,
                language: currentActivity?.languageId || LANGUAGE_ID.ENGLISH
            });
            if (ok) {
                return data;
            } else {
                notificationService.error(response.message);
                throw new Error(response.message);
            }
        },
        {
            enabled: visible && currentActivity?.activityDayId != null && !showActivityModal
        }
    );

    useEffect(() => {
        if (selectedActivity?.reportDetails) {
            setReportData(selectedActivity.reportDetails);
        }
        setCurrentActivity(selectedActivity);
    }, [selectedActivity]);

    return (
        <div className="view-activity-drawer">
            <Drawer
                placement={"right"}
                closable={false}
                onClose={onClose}
                visible={visible}
                key={"right"}
                className={`custom-drawer custom-drawer-header ${isReported ? "report-drawer" : "activity-drawer"}`}
                title={
                    <Row justify="space-between" align="center" className="gx-m-0 gx-p-0">
                        {isReported && (
                            <Col sm={24} xs={24} md={12} xl={12} lg={12} className="gx-pb-3 gx-pt-3">
                                <p className=" gx-m-0 gx-p-0 noirProRegular gx-font-weight-medium gx-fs-lg">
                                    Reporters
                                </p>
                            </Col>
                        )}
                        <Col
                            sm={24}
                            xs={24}
                            md={isReported && 12}
                            xl={isReported && 12}
                            lg={isReported && 12}
                            className="header-vertical-divider gx-pb-3 gx-pt-3"
                        >
                            <Row justify="space-between" align="center" className="gx-m-0 gx-p-0">
                                <Col className="align-self-center">
                                    <p className=" gx-m-0 gx-p-0 noirProRegular gx-font-weight-medium gx-fs-lg">
                                        {isReported ? "View Reported Event" : "View Event"}
                                    </p>
                                </Col>
                                <Col>
                                    <Space size="middle">
                                        <Skeleton
                                            active
                                            round
                                            loading={getActivityCallInProgress}
                                            paragraph={{ rows: 1, width: 100 }}
                                            title={false}
                                        >
                                            <p className="event-type-text gx-m-0 noirProRegular gx-font-weight-medium gx-fs-xs lineHeight1">
                                                {MAP_EVENT_TYPE[activityDetails?.type]}
                                            </p>
                                        </Skeleton>
                                        <CloseOutlined onClick={onClose} />
                                    </Space>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }
            >
                <Row justify="space-between" align="center" className="gx-m-0 gx-p-0">
                    {isReported && (
                        <Col sm={24} xs={24} md={12} xl={12} lg={12} className="vertical-scroll">
                            <Skeleton loading={getActivityCallInProgress} active>
                                {reportData?.map((item, index) => {
                                    let { reporterName, reason, reportedOn } = item;
                                    return (
                                        <>
                                            {index > 0 && <Divider className="width-100per divider-style" />}
                                            <div className="content-wrap align-item-center gx-mt-3">
                                                <i className="icon usd-warning pending-color gx-fs-lg" />
                                                <div>
                                                    <h5 className="gx-m-0 gx-font-weight-medium gx-mb-sm-1">
                                                        {reporterName}
                                                    </h5>
                                                    <h5 className="gx-m-0 gx-font-weight-light gx-mb-sm-1">{reason}</h5>
                                                </div>
                                            </div>
                                            <div className="content-wrap gx-mb-3">
                                                <i className="icon usd-warning transparent gx-fs-lg" />
                                                <p className="gx-m-0 gx-font-weight-light gx-fs-11">
                                                    {getDateDifference(reportedOn)}
                                                </p>
                                            </div>
                                        </>
                                    );
                                })}
                            </Skeleton>
                        </Col>
                    )}
                    <Col
                        sm={24}
                        xs={24}
                        md={isReported && 12}
                        xl={isReported && 12}
                        lg={isReported && 12}
                        className="vertical-divider vertical-scroll"
                    >
                        <Skeleton active loading={getActivityCallInProgress}>
                            <Row align="middle" justify="space-between" className="gx-p-0 gx-m-0">
                                <Col sm={24} xs={24} md={18} xl={20} lg={19} className="gx-p-0 ">
                                    <Row>
                                        <Col sm={24} xs={24} md={24} xl={24} lg={24}>
                                            <p className="title gx-m-0 gx-mt-2 noirProRegular gx-font-weight-medium gx-fs-xl">
                                                {activityDetails?.title}
                                            </p>
                                        </Col>
                                        {activityDetails?.neighborhood && (
                                            <Col sm={24} xs={24} md={24} xl={24} lg={24}>
                                                <Space size="small">
                                                    <div className="icon20">
                                                        <img src={LocationPin} />
                                                    </div>
                                                    <p className="text-color-body gx-m-0 noirProRegular gx-font-weight-normal gx-fs-md">
                                                        {activityDetails?.neighborhood}
                                                    </p>
                                                </Space>
                                            </Col>
                                        )}
                                    </Row>
                                </Col>
                                {!fromSearchActivity && (
                                    <Col sm={24} xs={24} md={6} xl={4} lg={5}>
                                        {/* <Dropdown
                                        overlay={
                                            <Menu mode="horizontal">
                                                {activityDetails?.actions?.map((item, index) => {
                                                    return (
                                                        <Menu.Item
                                                        // onClick={() => {
                                                        //     onActionClick(item.code, activityDetails);
                                                        // }}
                                                        // key={item.code}
                                                        // disabled={gridLoader}
                                                        >
                                                            {item.name}
                                                        </Menu.Item>
                                                    );
                                                })}
                                            </Menu>
                                        }
                                        placement="bottomRight"
                                    >
                                        <div className="circle-icon" align="center">
                                            <MoreOutlined className="circle-icon-content" />
                                        </div>
                                    </Dropdown> */}
                                        {renderAction(activityDetails, onActionClick)}
                                    </Col>
                                )}
                            </Row>
                            {activityDetails?.images?.length ? (
                                <div className="slider-container">
                                    <Carousel
                                        dotPosition="bottom"
                                        effect="fade"
                                        afterChange={(a, b, c) => console.log("")}
                                    >
                                        {activityDetails?.images?.map((imageInfo) => {
                                            return (
                                                <div>
                                                    <img
                                                        style={{ height: "225px", borderRadius: "4px" }}
                                                        src={imageInfo?.viewableLink}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </Carousel>
                                </div>
                            ) : (
                                <img
                                    className="img-placeholder"
                                    src={
                                        activityDetails?.type == ACTIVITY_TYPE.EVENT
                                            ? require("../../../../assets/images/drawer-placeholder.png")
                                            : require("../../../../assets/images/program.jpg")
                                    }
                                />
                            )}

                            <div className="gx-pb-3 gx-pt-3">
                                <p className="text-brand-primary-900 gx-m-0 noirProRegular gx-font-weight-medium gx-fs-lg">
                                    Description
                                </p>
                                <p className=" description-text text-color-body gx-pt-3 gx-m-0 noirProRegular gx-font-weight-medium ">
                                    {activityDetails?.description}
                                </p>
                            </div>

                            <div className="gx-pb-3">
                                <p className="text-brand-primary-900 gx-pb-2 gx-m-0 noirProRegular gx-font-weight-medium gx-fs-lg">
                                    When
                                </p>
                                {activityDetails?.recurrenceType == RECURRENCE_TYPE.SIMPLE ? (
                                    <Space size="small" className="align-item-start">
                                        <div className="icon20">
                                            <img src={Clock} />
                                        </div>
                                        <div>
                                            <Space className="content-wrap mb-14">
                                                <div className="gx-font-weight-medium">Every</div>
                                                {activityDetails.dateTimeDetails[0].every.map((item, index) => (
                                                    <div key={index}>
                                                        {DAYS_OF_WEEK[item]}
                                                        {index < activityDetails.dateTimeDetails[0].every.length - 2 &&
                                                            ", "}
                                                        {index == activityDetails.dateTimeDetails[0].every.length - 2 &&
                                                            " and "}
                                                    </div>
                                                ))}
                                            </Space>
                                            <Space className="width-100per">
                                                <p className="gx-font-weight-medium">Starting</p>
                                                <p>
                                                    {formatDateToSpecificTZ(
                                                        activityDetails?.recurrenceStartDate,
                                                        "MMMM DD, YYYY"
                                                    )}
                                                </p>
                                            </Space>
                                            <Space className="width-100per">
                                                <p className="gx-font-weight-medium">Ending</p>
                                                <p>
                                                    {formatDateToSpecificTZ(
                                                        activityDetails?.recurrenceEndDate,
                                                        "MMMM DD, YYYY"
                                                    )}
                                                </p>
                                            </Space>
                                            <Space className="width-100per">
                                                <p className="gx-font-weight-medium">Time</p>
                                                <p>
                                                    {`${formatDateToSpecificTZ(
                                                        activityDetails.dateTimeDetails[0].startDate,
                                                        DATE_TIME_FORMAT.AM_PM_FORMAT
                                                    )} - ${formatDateToSpecificTZ(
                                                        activityDetails.dateTimeDetails[0].endDate,
                                                        DATE_TIME_FORMAT.AM_PM_FORMAT
                                                    )}`}
                                                </p>
                                            </Space>
                                        </div>
                                    </Space>
                                ) : (
                                    <Space size="small">
                                        <div className="icon20">
                                            <img src={Clock} />
                                        </div>
                                        <p className="text-color-body gx-m-0 noirProRegular gx-font-weight-light gx-fs-md">
                                            {`${formatDateToSpecificTZ(
                                                activityDetails?.dateTimeDetails[0].startDate,
                                                DATE_FORMAT_1
                                            )}${formatDateToSpecificTZ(
                                                activityDetails?.dateTimeDetails[0].startDate,
                                                DATE_TIME_FORMAT.AM_PM_FORMAT
                                            )} - ${formatDateToSpecificTZ(
                                                activityDetails?.dateTimeDetails[0].endDate,
                                                DATE_TIME_FORMAT.AM_PM_FORMAT
                                            )}`}
                                        </p>
                                    </Space>
                                )}
                            </div>

                            {activityDetails?.recurrenceType == EVENT_RECURRENCE_TYPE.COMPLEX && (
                                <>
                                    {activityDetails.dateTimeDetails.some((item) => item.isExpired == false) && (
                                        <>
                                            <p className="text-brand-primary-900 noirProRegular gx-font-weight-medium gx-fs-lg">
                                                Also happening on
                                            </p>
                                            <div className="width330">
                                                <div className="horizontal-scroll">
                                                    {activityDetails.dateTimeDetails.map(
                                                        (item) =>
                                                            !item.isExpired && (
                                                                <div onClick={() => setCurrentActivity(item)}>
                                                                    <EventCard data={item} />
                                                                </div>
                                                            )
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {activityDetails.dateTimeDetails.some((item) => item.isExpired == true) && (
                                        <>
                                            <p className="text-brand-primary-900 noirProRegular gx-font-weight-medium gx-fs-lg">
                                                Also happened on
                                            </p>
                                            <div className="width330">
                                                <div className="horizontal-scroll">
                                                    {activityDetails.dateTimeDetails.map(
                                                        (item) =>
                                                            item.isExpired && (
                                                                <div onClick={() => setCurrentActivity(item)}>
                                                                    <EventCard data={item} />
                                                                </div>
                                                            )
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}

                            <div className="gx-pb-3">
                                <p className="text-brand-primary-900 gx-pb-2 gx-m-0 noirProRegular gx-font-weight-medium gx-fs-lg">
                                    Where
                                </p>

                                <Skeleton active loading={getActivityCallInProgress}>
                                    <Space size="small" className="align-item-start">
                                        <div className="icon20">
                                            <img src={LocationGreen} />
                                        </div>

                                        <div>
                                            <p className="text-color-body gx-m-0 noirProRegular gx-font-weight-medium gx-fs-md">
                                                {activityDetails?.address?.streetAddress}
                                                <br />
                                                <span style={{ display: "block" }} className="gx-pt-1">
                                                    {`${
                                                        activityDetails?.address.zipCode
                                                            ? `, ${activityDetails?.address.zipCode}`
                                                            : ""
                                                    }`}
                                                </span>
                                            </p>
                                            <div>
                                                <a
                                                    className="link-text gx-mt-3"
                                                    target={"_blank"}
                                                    href={`https://maps.google.com/?q=${activityDetails?.address?.latitude},${activityDetails?.address?.longitude}`}
                                                >
                                                    Get directions and see transportation information
                                                </a>
                                            </div>
                                        </div>
                                    </Space>
                                </Skeleton>
                            </div>

                            <div
                                className="gx-pb-3"
                                style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
                            >
                                <Row justify="space-between" align="center" className="gx-m-0 gx-p-0">
                                    <Col
                                        sm={24}
                                        xs={24}
                                        md={activityDetails?.website && 4}
                                        xl={activityDetails?.website && 4}
                                        lg={activityDetails?.website && 4}
                                        className="gx-p-0"
                                    >
                                        <p className="text-brand-primary-900 gx-pb-2 gx-m-0 noirProRegular gx-font-weight-medium gx-fs-lg">
                                            Cost
                                        </p>

                                        <Skeleton active loading={getActivityCallInProgress}>
                                            <Space size="small">
                                                <div className="icon20">
                                                    <img src={Dollar} />
                                                </div>

                                                <p className=" w-200 text-color-body gx-m-0 noirProRegular gx-font-weight-medium gx-fs-md">
                                                    {activityDetails?.costType === COST_TYPE.FREE
                                                        ? "Free"
                                                        : `${activityDetails?.discountedFrom} - ${activityDetails?.discountedTo}`}
                                                </p>
                                            </Space>
                                        </Skeleton>
                                    </Col>

                                    {activityDetails?.website && (
                                        <Col sm={24} xs={24} md={20} xl={20} lg={20}>
                                            <p className="text-brand-primary-900 gx-pb-2 gx-m-0 noirProRegular gx-font-weight-medium gx-fs-lg">
                                                Website
                                            </p>

                                            <Skeleton active loading={getActivityCallInProgress}>
                                                <Space size="small">
                                                    <div className="icon20">
                                                        <img src={LinkIcon} />
                                                    </div>
                                                    <div className="overflow-ellipses width215">
                                                        <a
                                                            className="link-text gx-m-0 gx-p-0 noirProRegular gx-font-weight-medium gx-fs-md"
                                                            href={activityDetails?.website}
                                                            target="_blank"
                                                        >
                                                            {activityDetails?.website}
                                                        </a>
                                                    </div>
                                                </Space>
                                            </Skeleton>
                                        </Col>
                                    )}
                                </Row>
                            </div>

                            <p className="text-brand-primary-900 gx-pb-2 gx-m-0 noirProRegular gx-font-weight-medium gx-fs-lg">
                                Sign Up
                            </p>
                            <div>
                                {activityDetails?.signUpLink ? (
                                    <Space size="small" className="mb-14">
                                        <div className="icon20">
                                            <img src={LinkIcon} />
                                        </div>
                                        <div className="overflow-ellipses">
                                            <a
                                                className="link-text gx-m-0 gx-p-0 noirProRegular gx-font-weight-medium gx-fs-md"
                                                href={activityDetails?.signUpLink}
                                                target="_blank"
                                            >
                                                {activityDetails?.signUpLink}
                                            </a>
                                        </div>
                                    </Space>
                                ) : (
                                    <p>No sign up required</p>
                                )}
                            </div>

                            <div className="gx-pb-3">
                                <p className="text-brand-primary-900 gx-pb-2 gx-m-0 noirProRegular gx-font-weight-medium gx-fs-lg">
                                    Host
                                </p>

                                <Skeleton active loading={getActivityCallInProgress}>
                                    <Space size="small">
                                        <div className="icon20">
                                            <img src={Emblem} />
                                        </div>
                                        <p className="text-color-body gx-m-0 noirProRegular gx-font-weight-medium gx-fs-md">
                                            {activityDetails?.host}
                                        </p>
                                    </Space>
                                </Skeleton>
                            </div>

                            {!!activityDetails?.ageGroups?.length && (
                                <div className="gx-pb-3">
                                    <p className="text-brand-primary-900 gx-pb-2 gx-m-0 noirProRegular gx-font-weight-medium gx-fs-lg">
                                        Children Age
                                    </p>
                                    <Skeleton active loading={getActivityCallInProgress}>
                                        <Space size="small" className="gx-align-items-start">
                                            <div className="icon20">
                                                <img src={Users} />
                                            </div>
                                            <div className="gx-flex-row">
                                                {activityDetails?.ageGroups?.map((info, i) => {
                                                    return (
                                                        <p className="text-color-body gx-m-0 noirProRegular gx-font-weight-medium gx-fs-md gx-pt-1 gx-pr-2 gx-pb-2">
                                                            {`${info?.description}${
                                                                i !== activityDetails?.ageGroups?.length - 1 ? "," : ""
                                                            }`}
                                                        </p>
                                                    );
                                                })}
                                            </div>
                                        </Space>
                                    </Skeleton>
                                </div>
                            )}

                            {activityDetails?.tags.length ? (
                                <div className="gx-pb-3">
                                    <p className="text-brand-primary-900 gx-pb-2 gx-m-0 noirProRegular gx-font-weight-medium gx-fs-lg">
                                        Tags
                                    </p>

                                    <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                                        {activityDetails.tags?.map((item, i) => {
                                            return (
                                                <p
                                                    key={item?.id}
                                                    className="tags gx-mb-2 gx-mr-2 gx-m-0 noirProRegular gx-font-weight-medium gx-fs-xs"
                                                >
                                                    {item?.name}
                                                </p>
                                            );
                                        })}
                                    </div>
                                </div>
                            ) : null}

                            {activityDetails?.transportations.length ? (
                                <div className="gx-pb-3">
                                    <p className="text-brand-primary-900 gx-pb-3 gx-m-0 noirProRegular gx-font-weight-medium gx-fs-lg">
                                        Public Transportation
                                    </p>

                                    <Skeleton active loading={getActivityCallInProgress}>
                                        {activityDetails?.transportations.map((info) => {
                                            return (
                                                <div
                                                    key={info?.id}
                                                    style={{
                                                        display: "flex",
                                                        flexWrap: "wrap",
                                                        alignItems: "center",
                                                        marginBottom: "8px"
                                                    }}
                                                >
                                                    <Space size="small">
                                                        <div className="icon20">
                                                            <img
                                                                src={
                                                                    info?.id === TRANSPORTATION_TYPE_ID.BUS
                                                                        ? Bus
                                                                        : Emblem
                                                                }
                                                            />
                                                        </div>
                                                        <p className="text-color-body gx-m-0 noirProRegular gx-font-weight-medium gx-fs-md">
                                                            {info?.name}
                                                        </p>
                                                    </Space>
                                                </div>
                                            );
                                        })}
                                    </Skeleton>
                                </div>
                            ) : null}

                            {activityDetails?.accomodations.length ? (
                                <div className="gx-pb-3">
                                    <p className="text-brand-primary-900 gx-pb-3 gx-m-0 noirProRegular gx-font-weight-medium gx-fs-lg">
                                        Accommodation
                                    </p>

                                    <Skeleton active loading={getActivityCallInProgress}>
                                        {activityDetails?.accomodations.map((info) => {
                                            return (
                                                <div className="accommodation-style">
                                                    <Space size="small">
                                                        <div className="icon20">
                                                            <img src={Checkmark} />
                                                        </div>
                                                        <p className="text-color-body gx-m-0 noirProRegular gx-font-weight-medium gx-fs-md">
                                                            {info?.name}
                                                        </p>
                                                    </Space>
                                                </div>
                                            );
                                        })}
                                    </Skeleton>
                                </div>
                            ) : null}
                        </Skeleton>
                    </Col>
                </Row>
            </Drawer>
        </div>
    );
};

export default ViewActivity;
