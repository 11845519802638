import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import CustomRoute from "./CustomRoute";

import { AUTHENTICATED_ROUTES, UNAUTHENTICATED_ROUTES } from "./constant";
import SuccessResult from "../containers/Result";
import AddNotificationContent from "../containers/DashboardPages/PushManagement/AddNotification/AddNotificationContent";

const Login = React.lazy(() => import("../containers/AuthPages/Login"));
const ForgetPassword = React.lazy(() => import("../containers/AuthPages/ForgetPassword"));
const ResetPassword = React.lazy(() => import("../containers/AuthPages/ResetPassword"));
const SetPassword = React.lazy(() => import("../containers/AuthPages/SetPassword/SetPassword"));
const AddActivity = React.lazy(() => import("../containers/AddActivity"));
const ActivityListing = React.lazy(() => import("../containers/DashboardPages/Activities/Activities"));
const UsersMap = React.lazy(() => import("../containers/AuthPages/GoogleMaps"));

export default function UnAuthenticatedApp() {
    return (
        <Switch>
            <CustomRoute exact path={UNAUTHENTICATED_ROUTES.LOGIN} component={Login} title="Login" />
            <CustomRoute
                exact
                path={UNAUTHENTICATED_ROUTES.FORGET_PASSWORD}
                component={ForgetPassword}
                title="Forgot Password"
            />
            <CustomRoute
                exact
                path={UNAUTHENTICATED_ROUTES.RESET_PASSWORD}
                component={ResetPassword}
                title="Reset Password"
            />
            <CustomRoute
                exact
                path={UNAUTHENTICATED_ROUTES.SET_PASSWORD}
                component={SetPassword}
                title="Set Password"
            />
            <CustomRoute
                exact
                path={UNAUTHENTICATED_ROUTES.ADD_ACTIVITY}
                component={AddActivity}
                title="Add Activity"
            />

            <CustomRoute
                exact
                path={UNAUTHENTICATED_ROUTES.EVENT_SUCCESS}
                component={SuccessResult}
                title="Event Success"
            />
            <CustomRoute exact path={UNAUTHENTICATED_ROUTES.GOOGLE_MAP} component={UsersMap} title="Users Map" />

            <Route exact path="*" component={Login}>
                <Redirect to={UNAUTHENTICATED_ROUTES.LOGIN} />
            </Route>
        </Switch>
    );
}
