import { useQuery } from "react-query";
import React, { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Space, Col, Row, Divider, Typography, Spin } from "antd";

import GamificationBadge from "./GamificationBadge";
import SubCommentReplies from "./SubCommentReplies";
import PinIcon from "../../../../assets/images/pin.svg";
import { API_STORAGE_KEY } from "../../../../constants/constant";
import CommentService from "../../../../ApiServices/comment.service";
import { getDateDifference } from "../../../../util/date-time.service";
import notificationService from "../../../../util/notification.service";
import UserIllustration from "../../../../assets/images/useravatar.svg";

const NO_OF_ROWS = 4;
const PAGINATION_OFFSET = "Pagination.Offset";
const PAGINATION_COUNT = "Pagination.Count";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Replies = ({ repliesDetail, LanguageId }) => {
    const { Paragraph } = Typography;
    const [repliesPayload, setRepliesPayload] = useState({
        PostId: repliesDetail?.postId,
        CommentId: repliesDetail?.id,
        LanguageId,
        [PAGINATION_OFFSET]: 0,
        [PAGINATION_COUNT]: 2
    });

    const getCommentReplies = async () => {
        const { data, ok, response } = await CommentService.getCommentReplies(repliesPayload);
        if (ok) {
            return data;
        } else {
            notificationService.error(response.message);
            throw new Error(response.message);
        }
    };
    const {
        data: subRepliesData,
        isFetching: subRepliesDataLoading,
        refetch: refetchReply
    } = useQuery(`${repliesDetail?.id}-${(API_STORAGE_KEY.SUB_REPLIES_DATA, LanguageId)}`, getCommentReplies, {
        enabled: repliesDetail?.repliesCount > 0
    });

    const onChangePageCount = () => {
        setRepliesPayload({
            ...repliesPayload,
            [PAGINATION_COUNT]: repliesPayload[PAGINATION_COUNT] + 3
        });
        setTimeout(() => {
            refetchReply();
        }, 100);
    };

    const remainingReplies = subRepliesData?.filteredCount - repliesPayload[PAGINATION_COUNT];

    return (
        <Row align="center" justify="space-between" className="gx-m-0 gx-pt-3" key={repliesDetail?.id}>
            <Col sm={3} xs={3} md={3} xl={3} lg={3} className="gx-p-0">
                {repliesDetail?.profilePictureUrl ? (
                    <img className="image-style" src={repliesDetail?.profilePictureUrl} />
                ) : (
                    <img className="icon-size-40" src={UserIllustration} />
                )}
                {repliesDetail.repliesCount > 0 && <Divider type="vertical" className="vertical-comment-divider" />}
            </Col>
            <Col sm={20} xs={20} md={20} xl={20} lg={20} className="gx-p-0">
                <Row className="gx-p-0 gx-m-0">
                    <Space className="gx-mb-2">
                        <h5 className="gx-font-weight-medium gx-m-0">{repliesDetail?.userName}</h5>
                        <GamificationBadge badgeList={repliesDetail?.badges?.badges} />
                        <Space>
                            <img className="icon-size-14" src={PinIcon} />
                            <h6 className="gx-m-0 gx-font-weight-light gx-text-gray">
                                {repliesDetail?.neighborhoodName}
                            </h6>
                        </Space>
                    </Space>
                </Row>
                <Row className="gx-p-0 gx-m-0">
                    <Paragraph
                        ellipsis={{ rows: NO_OF_ROWS, expandable: true, symbol: "more" }}
                        className="gx-font-weight-light gx-text-light-gray gx-fs-md gx-mb-2"
                    >
                        {repliesDetail?.content}
                    </Paragraph>
                    <h6 className="gx-m-0 gx-font-weight-light gx-text-gray width-100per">
                        {getDateDifference(repliesDetail?.createdOn)}
                    </h6>
                </Row>
                {repliesDetail?.repliesCount > 0 &&
                    subRepliesData?.list.map((subReply) => <SubCommentReplies subRepliesDetail={subReply} />)}
                <Spin indicator={antIcon} spinning={subRepliesDataLoading} className="width-100per gx-mt-3" />
                {remainingReplies > 0 && (
                    <div
                        className="gx-fs-13 pp-text-blue cursor gx-mt-3"
                        onClick={onChangePageCount}
                    >{`${remainingReplies} more ${remainingReplies > 1 ? "replies..." : "reply..."}`}</div>
                )}
            </Col>
        </Row>
    );
};
export default Replies;
