import { useQuery } from "react-query";
import React, { useEffect } from "react";
import { Select, SelectProps } from "antd";

import { ChildrenActivityService } from "../../../ApiServices";
import { API_STORAGE_KEY } from "../../../constants/constant";

const lookupSelect = ({ ok, data, response }: { ok: boolean; response: any; data: any }) => {
    if (ok) {
        const mutatedData = data?.categories?.map((item: any) => {
            return {
                ...item,
                value: item.id,
                label: item.name
            };
        });
        return mutatedData;
    }
    throw response?.message;
};

const ChildrenActivityLookup = (
    props: SelectProps & { setValue?: (a: any, b: any) => void; initialValue?: number }
) => {
    const { data: activityCategory = [], isLoading } = useQuery(
        API_STORAGE_KEY.ACTIVITY_CATEGORIES,
        () => ChildrenActivityService.getActivityCategory(),
        {
            select: lookupSelect
        }
    );

    useEffect(() => {
        if (props?.initialValue && activityCategory) {
            const { setValue, initialValue } = props;
            const selectedValue = activityCategory.filter((item: { id: number }) => item.id === initialValue);
            if (selectedValue[0] && setValue) setValue(null, { option: selectedValue[0] });
        }
    }, [activityCategory]);

    return (
        <Select
            {...props}
            showSearch
            style={{ width: "150px" }}
            loading={isLoading}
            disabled={isLoading}
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
        >
            {activityCategory?.map((data: any) => {
                return (
                    <Select.Option key={data?.value} value={data.value} option={data}>
                        {data?.name}
                    </Select.Option>
                );
            })}
        </Select>
    );
};
export default ChildrenActivityLookup;
