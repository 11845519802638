import "assets/vendors/style";
import { ConnectedRouter } from "connected-react-router";
import { ReactQueryDevtools } from "react-query/devtools";

import { QueryClient, QueryClientProvider, QueryCache } from "react-query";
import React from "react";
import { Provider } from "react-redux";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import "assets/vendors/style";

import configureStore, { history } from "./appRedux/store";
import withClearCache from "./ClearCache";
import FullPageLoader from "./components/FullPageLoader";
import { getNextPageParam } from "./util/react-query.service";
import Routes from "./routes";
import LocalStorageService from "./util/local-storage.service";
import AuthService from "./util/auth.service";
import { API_STORAGE_KEY, STORAGE_CONST, THEME } from "./constants/constant";
import MainApp from "./containers/App/MainApp";
import UnAuthenticatedApp from "./routes/unauthenticated-app-routes";

const store = configureStore();

const themes = {
    dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
    light: `${process.env.PUBLIC_URL}/light-theme.css`
};
const isTokenExist = AuthService.isTokenExist();

const localSaveTheme = LocalStorageService.get(STORAGE_CONST.THEME_TYPE);
const currentTheme = localSaveTheme && isTokenExist ? localSaveTheme : THEME.LITE;

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 * 30,
            refetchOnWindowFocus: false,
            retry: false,
            getNextPageParam: (lastPage, allPages) => getNextPageParam(lastPage, allPages)
        }
    }
});

export const queryCache = new QueryCache({
    onError: (error) => {
        console.log(error);
    },
    onSuccess: (data) => {
        console.log(data);
    }
});

const RegisterRoutes = () => {
    if (isTokenExist) {
        return <MainApp />;
    } else {
        return <UnAuthenticatedApp />;
    }
};

const App = () => (
    <React.Suspense fallback={<FullPageLoader />}>
        <React.StrictMode>
            <ThemeSwitcherProvider
                themeMap={themes}
                defaultTheme={currentTheme}
                insertionPoint="styles-insertion-point"
            >
                <Provider store={store}>
                    <QueryClientProvider client={queryClient}>
                        <ConnectedRouter history={history}>{RegisterRoutes()}</ConnectedRouter>
                        <ReactQueryDevtools initialIsOpen={false} />
                    </QueryClientProvider>
                </Provider>
            </ThemeSwitcherProvider>
        </React.StrictMode>
    </React.Suspense>
);

const ClearCacheComponent = withClearCache(App);

const NextApp = () => {
    return <ClearCacheComponent />;
};

export default NextApp;
