import React from "react";
import { Select } from "antd";
import { useQuery } from "react-query";

import { LookUpApiService } from "../../../ApiServices";
import notificationService from "../../../util/notification.service";
import { API_STORAGE_KEY } from "./../../../constants/constant";

const getUsers = async () => {
    const { ok, data, response } = await LookUpApiService.getUsersLookup();
    if (ok) {
        return data;
    } else {
        notificationService.error(response.message);
        throw new Error(response.message);
    }
};

const UserLookup = (props) => {
    const { isCustomizeWidth = true } = props;

    const { data: users = [], isLoading } = useQuery(API_STORAGE_KEY.NEIGHBORHOOD_LOOKUP, getUsers);

    return (
        <Select
            {...props}
            showSearch
            filterOption={(input, option) => {
                return option?.children?.toLowerCase().includes(input.toLowerCase());
            }}
            style={{
                ...(isCustomizeWidth && {
                    width: "150px !important",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis"
                }),

                minWidth: "150px"
            }}
            allowClear
            loading={isLoading}
            disabled={isLoading}
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
        >
            {users?.map((data) => {
                return (
                    <Select.Option key={data?.id} value={data.id}>
                        {data?.name}
                    </Select.Option>
                );
            })}
        </Select>
    );
};
export default UserLookup;
