import { Select } from "antd";
import React from "react";
import { STATUS_INFO } from "../../../constants/constant";

const StatusLookup = (props) => {
    return (
        <>
            <Select
                {...props}
                style={{ width: "150px" }}
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                allowClear
            >
                {STATUS_INFO?.map((data) => {
                    return (
                        <Select.Option key={data?.id} value={data.id}>
                            {data?.name}
                        </Select.Option>
                    );
                })}
            </Select>
        </>
    );
};
export default StatusLookup;
