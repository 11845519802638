import { useMutation, useQuery } from "react-query";
import ChildrenActivityService from "../../../ApiServices/children-activity.service";
import { API_STORAGE_KEY } from "../../../constants/constant";
import notificationService from "../../../util/notification.service";
import { PortalService } from "../../../ApiServices";

export const moduleName = "Children_Activity";

export const activityKeys = {
    categories: () => [moduleName, API_STORAGE_KEY.ACTIVITY_CATEGORIES],
    list: (payload: any) => [moduleName, API_STORAGE_KEY.ACTIVITY_LIST, payload],
    childActivity: (id: any) => [moduleName, id]
};

export function useChildActivity(id: any) {
    return useQuery(
        activityKeys.childActivity(id),
        async () => {
            const { ok, data, response } = await ChildrenActivityService.getChildrenActivity(id);
            if (ok) {
                return data;
            }
        },
        { enabled: !!id }
    );
}

export function useChildrenActivities(payload: any) {
    return useQuery(
        activityKeys.list(payload),
        async () => {
            const { ok, data, response } = await PortalService.getChildrenActivities({
                ...payload
            });
            if (ok) {
                return data;
            }
        },
        { keepPreviousData: true, staleTime: Infinity }
    );
}
export const useAddChildActivity = () => {
    return useMutation((payload) => ChildrenActivityService.addChildrenActivity(payload), {
        onSuccess: ({ ok, response, data }) => {
            if (ok) {
                notificationService.success(`${response?.message}.`, "", true);
            } else {
                notificationService.error(`${response?.message}.`, "", true);
            }
        }
    });
};

export const useUpdateChildActivity = () => {
    return useMutation(
        (payload: { id: number; body: any }) => ChildrenActivityService.updateChildrenActivity(payload),
        {
            onSuccess: ({ ok, response, data }) => {
                if (ok) {
                    notificationService.success(`${response?.message}.`, "", true);
                } else {
                    notificationService.error(`${response?.message}.`, "", true);
                }
            }
        }
    );
};
export const useGetActivityCategories = () => {
    return useQuery(activityKeys.categories(), async () => {
        const { data, ok, response } = await ChildrenActivityService.getActivityCategory();
        if (ok) {
            const mutatedData = data?.categories?.map((item: { id: number }) => {
                return {
                    ...item,
                    key: String(item?.id)
                };
            });
            return { data: mutatedData, doesSeasonalExist: data?.doesSeasonalExist };
        } else {
            notificationService.error(response?.message);
            throw new Error(response?.message);
        }
    });
};

export const useAddActivityCategory = (onSuccess: () => void) => {
    return useMutation(async (payload) => {
        const { data, ok, response } = await PortalService.addActivityCategory(payload);
        if (ok) {
            onSuccess();
            notificationService.success(`Category Added Successfully.`, "", true);
            return data;
        } else {
            notificationService.error(`${response?.message}.`, "", true);
            throw new Error(response?.message);
        }
    });
};

export const useUpdateActivityCategory = (onSuccess: () => void) => {
    return useMutation(async (payload: { id: number; [key: string]: any }) => {
        const { id, ...rest } = payload;
        const { data, ok, response } = await PortalService.updateActivityCategory(id, { ...rest });
        if (ok) {
            onSuccess();
            return data;
        } else {
            notificationService.error(`${response?.message}.`, "", true);
            throw new Error(response?.message);
        }
    });
};

export const useDeleteActivityCategory = (onSuccess: () => void) => {
    return useMutation((id: number) => PortalService.deleteActivityCategory(id), {
        onSuccess,
        onError: (err) => {
            throw err;
        }
    });
};

export const useDeleteChildrenActivity = (onSuccess: () => void) => {
    return useMutation((id: number) => ChildrenActivityService.deleteChildrenActivity(id), {
        onSuccess,
        onError: (err) => {
            throw err;
        }
    });
};

export const useChangeActivityOrder = (onSuccess: () => void) => {
    return useMutation(async (payload) => {
        const { data, ok, response } = await PortalService.updateActivityCategoryOrder(payload);
        if (ok) {
            onSuccess();
            return data;
        } else {
            throw new Error(response?.message);
        }
    });
};
