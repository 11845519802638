import React from "react";
import { Tooltip } from "antd";

const GamificationBadge = (props) => {
    const { badgeList = [] } = props;

    return (
        <div className="badge-container">
            {badgeList?.map((badge) => (
                <Tooltip placement="bottom" color={badge.color} title={badge.name}>
                    <img className="gamification-badge" src={badge.media.viewableLink} />
                </Tooltip>
            ))}
        </div>
    );
};

export default GamificationBadge;
