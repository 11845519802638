import ApiService from "../util/api.service";
import { SERVICE_CONFIG_URLS } from "./config";
import utilService from "../util/utils.service";

const AuthApiService = {
    banUser,
    deleteAccount
};

async function banUser(userId, payload) {
    const url = utilService.createDynamicUrl(SERVICE_CONFIG_URLS.AUTHENTICATION.BAN_USER, userId);
    const response = await ApiService.put(url, payload);
    return response;
}

async function deleteAccount(accountId) {
    const url = utilService.createDynamicUrl(SERVICE_CONFIG_URLS.AUTHENTICATION.DELETE_ACCOUNT, accountId);
    const response = await ApiService.delete(url);
    return response;
}

export default AuthApiService;
