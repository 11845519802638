import ApiService from "../util/api.service";
import utilService from "../util/utils.service";
import { SERVICE_CONFIG_URLS } from "./config";

const CommentService = {
    getCommentDetail,
    deleteComment,
    getCommentList,
    getCommentReplies
};

async function getCommentDetail(query) {
    const url = utilService.createDynamicUrl(SERVICE_CONFIG_URLS.COMMENT.GET_COMMENT_DETAILS, query);
    const response = await ApiService.get(url);
    return response;
}

async function deleteComment(query, action) {
    const url = utilService.createDynamicUrl(SERVICE_CONFIG_URLS.COMMENT.DELETE_COMMENT, query);
    const response = await ApiService.delete(url, action);
    return response;
}

async function getCommentList(payload) {
    const response = await ApiService.get(SERVICE_CONFIG_URLS.COMMENT.GET_COMMENT_LIST, payload);
    return response;
}

async function getCommentReplies(payload) {
    const response = await ApiService.get(SERVICE_CONFIG_URLS.COMMENT.COMMENT_REPLIES, payload);
    return response;
}

export default CommentService;
