import { useMutation, useQuery, useQueryClient } from "react-query";

import {
    PortalService,
    AuthApiService,
    ActivityService,
    NotificationService,
    LookUpApiService
} from "../../../ApiServices";
import notificationService from "../../../util/notification.service";
import { API_STORAGE_KEY, IN_APP_TAB_IDS, LANGUAGE_ID } from "../../../constants/constant";
import utilService from "../../../util/utils.service";
import { queryClient } from "../../../NextApp";
import { getSearchApiMethod, keyInfo } from "./helper";
import { useChildrenActivities } from "../Activity/queries";

const moduleName = "notificationManagement";

const notificationKeys = {
    notificationList: (payload) => [moduleName, "list", payload],
    notificationDetails: (id) => [moduleName, "details", id],
    notificationInAppUri: (selectedTab, payload, explorePayload) => [
        API_STORAGE_KEY[keyInfo(selectedTab)],
        payload,
        explorePayload
    ]
};
function useNotificationList(payload) {
    return useQuery(
        notificationKeys.notificationList(payload),
        async () => {
            const { ok, data, response } = await PortalService.getNotifications({
                ...payload
            });
            if (ok) {
                return {
                    ...data,
                    list: data?.list?.map((el) => {
                        return {
                            ...el,
                            languageId: LANGUAGE_ID.ENGLISH
                        };
                    })
                };
            }
        },
        { keepPreviousData: true, staleTime: Infinity }
    );
}

function useAddNotification({ onSuccess }) {
    return useMutation(async (payload) => {
        const { data, ok, response } = await NotificationService.addNotification(payload);
        if (ok) {
            onSuccess();
            notificationService.success(`Push notification has been scheduled.`, "", true);
            return data;
        } else {
            notificationService.error(`${response?.message}.`, "", true);
            throw new Error(response?.message);
        }
    });
}

function useDeleteNotification({ onSuccess, onError }) {
    return useMutation(async (id) => {
        const { data, ok, response } = await NotificationService.deleteNotification(id);
        if (ok) {
            notificationService.success("Delete Notification", `Notification was successfully Deleted.`, true);
            onSuccess();
            return data;
        } else {
            notificationService.error("Delete Notification", `${response?.message}.`, true);
            onError();
            throw new Error(response?.message);
        }
    });
}

function useUpdateNotification({ onSuccess }) {
    return useMutation(async (payload) => {
        const { data, ok, response } = await NotificationService.updateNotification({ id: payload.id }, payload);
        if (ok) {
            notificationService.success("Update Notification", `Notification was successfully Updated.`, true);
            onSuccess();
            return data;
        } else {
            notificationService.error("Update Notification", `${response?.message}.`, true);
            throw new Error(response?.message);
        }
    });
}

function useNotificationUserCount() {
    return useMutation(async (payload) => {
        const { data, ok, response } = await NotificationService.getNotificationUserCount(payload);
        if (ok) {
            return data;
        } else {
            notificationService.error(`${response?.message}.`, "", true);
            throw new Error(response?.message);
        }
    });
}

function useNotificationDetails({ id, onSuccess }) {
    return useQuery(
        notificationKeys.notificationDetails(id),
        async () => {
            const { data, ok, response } = await NotificationService.getNotificationDetails({ id });
            if (ok) {
                onSuccess(data);
                return data;
            } else {
                notificationService.error(response?.message);
                throw new Error(response?.message);
            }
        },
        { enabled: !!id }
    );
}

function useNotificationInAppUri({ onSuccess, payload, explorePayload, selectedTab, visible }) {
    return useQuery(
        notificationKeys.notificationInAppUri(selectedTab, payload, explorePayload),
        async () => {
            const isExploreKey = selectedTab == IN_APP_TAB_IDS.EXPLORE;
            const { data, ok, response } = isExploreKey
                ? await PortalService.getChildrenActivities(explorePayload)
                : await LookUpApiService[getSearchApiMethod(selectedTab)](payload);
            if (ok) {
                return data;
            } else {
                notificationService.error(response?.message);
                throw new Error(response?.message);
            }
        },
        {
            enabled: Boolean(payload.searchText) && visible,
            staleTime: Infinity,
            onSuccess: onSuccess
        }
    );
}

export {
    useNotificationList,
    useAddNotification,
    useDeleteNotification,
    useUpdateNotification,
    useNotificationDetails,
    useNotificationUserCount,
    useNotificationInAppUri
};
