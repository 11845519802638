import React from "react";
import moment from "moment";
import momentTimezone from "moment-timezone";
import "moment/min/locales";
import { API_STORAGE_KEY, YEAR_FORMAT } from "./../constants/constant";
import utilService from "./utils.service";
import { queryClient } from "../NextApp";
import { DASHBOARD_DATE_TYPE, REPORT_DATE_TYPE } from "../constants/constantTypes";
import { DatePicker } from "antd";

export const DATE_TIME_FORMAT = {
    US_DATE_FORMAT_SLASH: "MM/DD/YYYY",
    FORMAT: "YYYY-MM-DD",
    LONG_DATE_FORMAT: "YYYY-MM-DD HH:mm:ss",
    US_DATE_FORMAT: "MM-DD-YYYY",
    DATE_FORMAT: "MM-DD-YYYY",
    TIME: "hh:mm A",
    US_DATE_TIME_FORMAT: "MM/DD/YY - h:mm A",
    US_TIME_FORMAT: "h:mm A",
    YEAR: "YYYY",
    FORMAT_TIME_ZONE: "YYYY-MM-DDTHH:mm:ss.SSZ",
    AM_PM_FORMAT: "hh:mm a",
    DATE_AND_TIME_FORMAT_LONG: "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
};

const timeZone = "America/Chicago";

export function getLocalTime(date, format = DATE_TIME_FORMAT.AM_PM_FORMAT) {
    return moment.utc(date).local().format(format);
}

export function getFormateLocalTimeZone(date, format = DATE_TIME_FORMAT.US_DATE_FORMAT) {
    if (date) return moment.utc(date).local().format(format);
    return undefined;
}

export const changeFormat = (string, requiredFormat = DATE_TIME_FORMAT.FORMAT) => {
    if (!string) {
        return null;
    }
    return moment.utc(string).local().format(requiredFormat);
};

export const changeFormatV2 = (string, requiredFormat = DATE_TIME_FORMAT.FORMAT) => {
    if (!string) {
        return null;
    }
    return moment(string).format(requiredFormat);
};

export const getDateTimeCST = (date, time, timeZone) => {
    if (!timeZone) {
        const userDetails = queryClient.getQueryData(API_STORAGE_KEY.USER_DETAILS);
        timeZone = userDetails?.timezone?.conversionKey;
    }
    return momentTimezone
        .tz(`${date} ${time}`, `${DATE_TIME_FORMAT.FORMAT} ${DATE_TIME_FORMAT.TIME}`, timeZone)
        .utc()
        .format("yyyy-MM-DD[T]HH:mm:SS");
};

export const getDateTimeUTC = (date, time) => {
    if (time == null) {
        time = "11:59 pm";
    }
    const dateObject = moment(time, "hh:mm a").toObject();
    return moment(date)
        .set({
            hours: dateObject.hours,
            minutes: dateObject.minutes
        })
        .format();
    const convertedTime = moment(time, "hh:mm a").format("HH:mm");
    let dateAndTime = moment(`${date} ${convertedTime}`).utc().format();

    return dateAndTime;
};

export const getDateDifference = (date, offset) => {
    if (!offset) {
        const userDetails = queryClient.getQueryData(API_STORAGE_KEY.USER_DETAILS);
        offset = userDetails?.timezone && utilService.getMinutesFromTZ(userDetails?.timezone);
    }
    const dateObject = moment.utc(date).utcOffset(offset);
    let hours = dateObject.format("hh:mm a");
    let monthdate = dateObject.format(DATE_TIME_FORMAT.US_DATE_FORMAT_SLASH);
    var current = moment().startOf("day");
    let days = moment.duration(current.diff(dateObject)).asDays();
    let result = "";
    if (days <= 1) {
        return (result = `${"Today"}, ` + `${hours}`);
    }
    if (days > 1 && days < 2) {
        return (result = `${"Yesterday"}, ` + `${hours}`);
    }
    return result + `${monthdate}, ` + `${hours}`;
};

export const getDateFilter = (id) => {
    const date = { toDate: moment().format(), fromDate: null };
    if (DASHBOARD_DATE_TYPE.ALL_TIME == id || id == undefined) {
        return { ...date, toDate: null };
    } else if (DASHBOARD_DATE_TYPE.WEEKLY == id) {
        return { ...date, fromDate: moment().subtract(1, "w").format() };
    } else if (DASHBOARD_DATE_TYPE.MONTHLY == id) {
        return { ...date, fromDate: moment().subtract(1, "M").format() };
    }
};
export const getReportDateFilter = (id) => {
    const date = { toDate: moment().format(), fromDate: null };
    const currentYear = moment().year();
    const previousYear = currentYear - 1;
    switch (id) {
        case REPORT_DATE_TYPE.ALL_TIME || undefined:
            return { ...date, toDate: null };
        case REPORT_DATE_TYPE.TODAY:
            return {
                fromDate: moment().startOf("day").format(YEAR_FORMAT),
                toDate: moment().endOf("day").format(YEAR_FORMAT)
            };
        case REPORT_DATE_TYPE.YESTERDAY:
            return {
                fromDate: moment().subtract(1, "days").startOf("day").format(YEAR_FORMAT),
                toDate: moment().subtract(1, "days").endOf("day").format(YEAR_FORMAT)
            };
        case REPORT_DATE_TYPE.THIS_WEEK:
            return {
                fromDate: moment().startOf("w").format(YEAR_FORMAT),
                toDate: moment().endOf("w").format(YEAR_FORMAT)
            };
        case REPORT_DATE_TYPE.LAST_WEEK:
            return {
                fromDate: moment().subtract(1, "week").startOf("week").format(YEAR_FORMAT),
                toDate: moment().subtract(1, "week").endOf("week").format(YEAR_FORMAT)
            };
        case REPORT_DATE_TYPE.THIS_MONTH:
            return {
                fromDate: moment().startOf("M").format(YEAR_FORMAT),
                toDate: moment().format(YEAR_FORMAT)
            };
        case REPORT_DATE_TYPE.LAST_MONTH:
            return {
                fromDate: moment().subtract(1, "months").startOf("month").format(YEAR_FORMAT),
                toDate: moment().subtract(1, "months").endOf("month").format(YEAR_FORMAT)
            };
        case REPORT_DATE_TYPE.Q1_THIS_YEAR:
            return {
                fromDate: moment(`${currentYear}-01-01`).format(YEAR_FORMAT),
                toDate: moment(`${currentYear}-03-31`).format(YEAR_FORMAT)
            };
        case REPORT_DATE_TYPE.Q2_THIS_YEAR:
            return {
                fromDate: moment(`${currentYear}-04-01`).format(YEAR_FORMAT),
                toDate: moment(`${currentYear}-06-30`).format(YEAR_FORMAT)
            };
        case REPORT_DATE_TYPE.Q3_THIS_YEAR:
            return {
                fromDate: moment(`${currentYear}-07-01`).format(YEAR_FORMAT),
                toDate: moment(`${currentYear}-09-30`).format(YEAR_FORMAT)
            };
        case REPORT_DATE_TYPE.Q4_THIS_YEAR:
            return {
                fromDate: moment(`${currentYear}-10-01`).format(YEAR_FORMAT),
                toDate: moment(`${currentYear}-12-31`).format(YEAR_FORMAT)
            };
        case REPORT_DATE_TYPE.Q1_LAST_YEAR:
            return {
                fromDate: moment(`${previousYear}-01-01`).format(YEAR_FORMAT),
                toDate: moment(`${previousYear}-03-31`).format(YEAR_FORMAT)
            };
        case REPORT_DATE_TYPE.Q2_LAST_YEAR:
            return {
                fromDate: moment(`${previousYear}-04-01`).format(YEAR_FORMAT),
                toDate: moment(`${previousYear}-06-30`).format(YEAR_FORMAT)
            };
        case REPORT_DATE_TYPE.Q3_LAST_YEAR:
            return {
                fromDate: moment(`${previousYear}-07-01`).format(YEAR_FORMAT),
                toDate: moment(`${previousYear}-09-30`).format(YEAR_FORMAT)
            };
        case REPORT_DATE_TYPE.Q4_LAST_YEAR:
            return {
                fromDate: moment(`${previousYear}-10-01`).format(YEAR_FORMAT),
                toDate: moment(`${previousYear}-12-31`).format(YEAR_FORMAT)
            };
        case REPORT_DATE_TYPE.THIS_YEAR:
            return {
                fromDate: moment().startOf("year").format(YEAR_FORMAT),
                toDate: moment().format(YEAR_FORMAT)
            };
        case REPORT_DATE_TYPE.LAST_YEAR:
            return {
                fromDate: moment().subtract(1, "year").startOf("year").format(YEAR_FORMAT),
                toDate: moment().subtract(1, "year").endOf("year").format(YEAR_FORMAT)
            };
    }
};

export const getCSTDateDifference = (date, format = DATE_TIME_FORMAT.US_DATE_FORMAT_SLASH, offset) => {
    if (!offset) {
        const userDetails = queryClient.getQueryData(API_STORAGE_KEY.USER_DETAILS);
        offset = userDetails?.timezone && utilService.getMinutesFromTZ(userDetails?.timezone);
    }
    const dateObject = moment.utc(date).utcOffset(offset);
    let hours = dateObject.format("hh:mm a");
    let monthDate = dateObject.format(format);
    var current = moment().utcOffset(offset).startOf("day");
    let days = moment.duration(current.diff(dateObject)).asDays();
    let result = "";
    if (days <= 1) {
        return (result = `${"Today"}, ${hours}`);
    }
    if (days > 1 && days < 2) {
        return (result = `${"Yesterday"}, ${hours}`);
    }
    return `${result}${monthDate} - ${hours} CST`;
};

export function formatDateToSpecificTZ(date, format = "MM/DD/YYYY", offset) {
    if (!date) {
        return null;
    }
    if (!offset) {
        const userDetails = queryClient.getQueryData(API_STORAGE_KEY.USER_DETAILS);
        offset = userDetails?.timezone && utilService.getMinutesFromTZ(userDetails?.timezone);
    }
    return moment.utc(date).utcOffset(offset).format(format);
}

export function formatDateToSpecificTZv2(date, format = "MM/DD/YYYY", timezone) {
    if (!date) {
        return null;
    }
    if (!timezone) {
        const userDetails = queryClient.getQueryData(API_STORAGE_KEY.USER_DETAILS);
        timezone = userDetails?.timezone?.conversionKey;
    }
    var utcCutoff = moment.utc(date, DATE_TIME_FORMAT.FORMAT_TIME_ZONE);
    var displayCutoff = utcCutoff.clone()?.tz(timezone)?.format(format);
    return displayCutoff;
}

export function DateToSpecificTZ(date, offset) {
    if (!date) {
        return null;
    }
    if (!offset) {
        const userDetails = queryClient.getQueryData(API_STORAGE_KEY.USER_DETAILS);
        offset = userDetails?.timezone && utilService.getMinutesFromTZ(userDetails?.timezone);
    }
    const currentOffset = moment().utcOffset();
    return moment.utc(date).local().subtract(currentOffset, "minutes").add("minutes", offset);
}

export function DateToSpecificTZV2(date, offset) {
    if (!date) {
        return null;
    }
    if (!offset) {
        const userDetails = queryClient.getQueryData(API_STORAGE_KEY.USER_DETAILS);
        offset = userDetails?.timezone?.conversionKey;
    }
    const currentOffset = moment.tz(offset).utcOffset();

    return moment.utc(date).local().add("minutes", currentOffset);
}

export function DateToSpecificTZFormated(date, offset) {
    if (!date) {
        return null;
    }
    if (!offset) {
        const userDetails = queryClient.getQueryData(API_STORAGE_KEY.USER_DETAILS);
        offset = userDetails?.timezone && utilService.getMinutesFromTZ(userDetails?.timezone);
    }
    return moment.utc(date).format();
}

function formatDateUtc(date, format = "MM/DD/YYYY") {
    if (!date) {
        return null;
    }
    const offset = momentTimezone(date).tz(timeZone).format("Z");
    return momentTimezone.utc(date).utcOffset(offset).format(format);
}

function isAfter(date1, date2) {
    return moment(date1).isAfter(date2);
}

export function getToFrom(dateString) {
    if (!dateString) {
        return null;
    }
    const dateObject = moment(dateString, "MM-DD-YYYY");
    return {
        startDate: dateObject.clone().startOf("day").utc().format(),
        endDate: dateObject.clone().endOf("day").utc().format()
    };
}
export const getDateTimeInUtc = (date, format = DATE_TIME_FORMAT.DATE_AND_TIME_FORMAT_LONG) => {
    return moment.utc(date).format(format);
};

export const getDateTimeMomentInLocal = (date) => {
    return moment.utc(date).local();
};

const dateTimeService = {
    formatDateUtc,
    isAfter,
    DateToSpecificTZ,
    DateToSpecificTZFormated,
    formatDateToSpecificTZ,
    DATE_TIME_FORMAT
};

export default dateTimeService;
