import React, { useEffect, useState } from "react";
import packageJson from "../package.json";
import dateTimeService from "./util/date-time.service";

const buildDateGreaterThan = (latestDate, currentDate) => {
    return dateTimeService.isAfter(latestDate, currentDate);
};

function withClearCache(Component) {
    function ClearCacheComponent(props) {
        const [isLatestBuildDate, setIsLatestBuildDate] = useState(true);

        useEffect(() => {
            const checkBuildDate = async () => {
                try {
                    const response = await fetch("/meta.json");
                    if (!response.ok) {
                        throw new Error("Failed to fetch meta.json");
                    }
                    const meta = await response.json();
                    const latestVersionDate = meta.buildDate;
                    const currentVersionDate = packageJson.buildDate;

                    if (buildDateGreaterThan(latestVersionDate, currentVersionDate)) {
                        setIsLatestBuildDate(false);
                        await refreshCacheAndReload();
                    }
                } catch (error) {
                    console.error("Error checking build date", error);
                }
            };

            checkBuildDate();
        }, []);

        const refreshCacheAndReload = async () => {
            if ("serviceWorker" in navigator) {
                try {
                    const registration = await navigator.serviceWorker.getRegistration();
                    if (registration) {
                        await registration.unregister();
                    }
                    const cacheKeys = await caches.keys();
                    await Promise.all(cacheKeys.map((key) => caches.delete(key)));
                    window.location.reload(true);
                } catch (error) {
                    console.error("Error during cache and reload process", error);
                    window.location.reload(true); // Fallback to reload in case of error
                }
            } else {
                window.location.reload(true);
            }
        };

        return <React.Fragment>{isLatestBuildDate ? <Component {...props} /> : null}</React.Fragment>;
    }

    return ClearCacheComponent;
}

export default withClearCache;
