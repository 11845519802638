import ApiService from "../util/api.service";

import utilService from "../util/utils.service";
import { SERVICE_CONFIG_URLS } from "./config";

const RecommendConnectService = {
    deleteCommunityPartner,
    addCommunityPartner,
    suggestPartnerDetails,
    updateCommunityPartner,
    getCommunityPartners,
    getConnectCategories
};

async function getCommunityPartners(payload) {
    const response = await ApiService.get(SERVICE_CONFIG_URLS.RECOMMEND_CONNECT.COMMUNITY_PARTNER_LIST, payload);
    return response;
}

async function addCommunityPartner(payload) {
    const response = await ApiService.post(SERVICE_CONFIG_URLS.RECOMMEND_CONNECT.ADD_COMMUNITY_PARTNER, payload);
    return response;
}
async function updateCommunityPartner(payload, id) {
    const url = utilService.createDynamicUrl(SERVICE_CONFIG_URLS.RECOMMEND_CONNECT.UPDATE_COMMUNITY_PARTNER, id);
    const response = await ApiService.put(url, payload);
    return response;
}

async function deleteCommunityPartner(id, type) {
    const url = utilService.createDynamicUrl(SERVICE_CONFIG_URLS.RECOMMEND_CONNECT.DELETE_COMMUNITY_PARTNER, id);
    const response = await ApiService.delete(url);
    return response;
}

async function suggestPartnerDetails(id) {
    const url = utilService.createDynamicUrl(SERVICE_CONFIG_URLS.RECOMMEND_CONNECT.COMMUNITY_PARTNER_DETAILS, { id });
    const response = await ApiService.get(url);
    return response;
}

async function getConnectCategories() {
    const response = await ApiService.get(SERVICE_CONFIG_URLS.RECOMMEND_CONNECT.CONNECT_CATEGORIES);
    return response;
}

export default RecommendConnectService;
