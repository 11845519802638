import { useQuery } from "react-query";
import React, { useState, useEffect } from "react";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { Drawer, Col, Row, Skeleton, Divider, Spin } from "antd";

import Post from "./Post";
import Comment from "./Comment";
import PostService from "../../../../ApiServices/post.service";
import { API_STORAGE_KEY, LANGUAGE_ID } from "../../../../constants/constant";
import { CHAT_CONTENT_TYPE } from "../../../../constants/constantTypes";
import CommentService from "../../../../ApiServices/comment.service";
import { getDateDifference } from "../../../../util/date-time.service";
import notificationService from "../../../../util/notification.service";

import "./index.less";
import { contentTitle } from "../../Modal/helper";

const PAGINATION_OFFSET = "Pagination.Offset";
const PAGINATION_COUNT = "Pagination.Count";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const ContentDrawer = ({ visible, onClose, selectedContent, isReported = false, onActionClick, showAction = true }) => {
    const [currentContent, setCurrentContent] = useState();
    const [reportData, setReportData] = useState();
    const [currentLanguage, setCurrentLanguage] = useState(null);
    const [pageCount, setPageCount] = useState(5);

    useEffect(() => {
        if (!currentLanguage) {
            setCurrentLanguage(selectedContent?.languageId ?? 1);
        }
    }, [selectedContent]);

    const getPost = async () => {
        const { data, ok, response } = await PostService.getPostDetail(
            { id: currentContent.postId },
            {
                CommentId: CHAT_CONTENT_TYPE.COMMENT == currentContent?.entityType ? currentContent?.entityId : null,
                languageId: currentLanguage
            }
        );
        if (ok) {
            data.entityId = currentContent?.entityId;
            data.entityType = currentContent?.entityType;
            data.entityTypeName = currentContent?.entityTypeName;
            return data;
        } else {
            notificationService.error(response.message);
            throw new Error(response.message);
        }
    };
    const { data: postData, isLoading: postDataLoading } = useQuery(
        [API_STORAGE_KEY.CHAT_POST, currentContent?.postId, currentLanguage],
        getPost,
        {
            enabled: visible && currentContent?.postId != null
        }
    );

    const getComments = async () => {
        const { data, ok, response } = await CommentService.getCommentList({
            LanguageId: currentLanguage,
            CommentId: CHAT_CONTENT_TYPE.COMMENT == currentContent?.entityType ? currentContent?.entityId : null,
            PostId: currentContent.postId,
            [PAGINATION_OFFSET]: 0,
            [PAGINATION_COUNT]: pageCount
        });
        if (ok) {
            if (CHAT_CONTENT_TYPE.COMMENT == currentContent?.entityType) data.list.unshift(postData?.reportedComment);
            return data;
        } else {
            notificationService.error(response.message);
            throw new Error(response.message);
        }
    };
    const {
        data: commentData,
        isLoading: commentDataLoading,
        isFetching: commentDataFetching,
        refetch: refetchComment
    } = useQuery(
        [API_STORAGE_KEY.CHAT_COMMENT, currentContent?.entityId ?? currentContent?.postId, currentLanguage],
        getComments,
        {
            enabled: visible && currentContent?.postId != null && !postDataLoading
        }
    );

    const onChangePageCount = () => {
        setPageCount((prevState) => prevState + 5);
        setTimeout(() => {
            refetchComment();
        }, 100);
    };

    useEffect(() => {
        if (selectedContent) {
            if (selectedContent?.reportDetails) {
                setReportData(selectedContent.reportDetails);
            }
            setCurrentContent(selectedContent);
        }
    }, [selectedContent]);

    const remainingComment = commentData?.filteredCount - pageCount;

    return (
        <div className="view-activity-drawer">
            <Drawer
                placement={"right"}
                closable={false}
                onClose={onClose}
                visible={visible}
                key={"right"}
                className={`custom-drawer custom-drawer-header ${isReported ? "report-drawer" : "activity-drawer"}`}
                title={
                    <Row justify="space-between" align="center" className="gx-m-0 gx-p-0">
                        {isReported && (
                            <Col sm={24} xs={24} md={12} xl={12} lg={12} className="gx-pb-3 gx-pt-3">
                                <p className=" gx-m-0 gx-p-0 noirProRegular gx-font-weight-medium gx-fs-lg">
                                    Reporters
                                </p>
                            </Col>
                        )}
                        <Col
                            sm={24}
                            xs={24}
                            md={isReported && 12}
                            xl={isReported && 12}
                            lg={isReported && 12}
                            className="header-vertical-divider gx-pb-3 gx-pt-3"
                        >
                            <Row justify="space-between" align="center" className="gx-m-0 gx-p-0">
                                <Col className="align-self-center">
                                    <p className=" gx-m-0 gx-p-0 noirProRegular gx-font-weight-medium gx-fs-lg">
                                        {isReported ? "View Reported Content" : "View Content"}
                                    </p>
                                </Col>
                                <Col>
                                    <CloseOutlined onClick={onClose} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }
            >
                <Row justify="space-between" align="center" className="gx-m-0 gx-p-0">
                    {isReported && (
                        <Col sm={24} xs={24} md={12} xl={12} lg={12} className="vertical-scroll">
                            <Skeleton active loading={postDataLoading || commentDataLoading}>
                                {reportData?.map((item, index) => {
                                    let { reporterName, reason, reportedOn } = item;
                                    return (
                                        <>
                                            {index > 0 && <Divider className="width-100per divider-style" />}
                                            <div className="content-wrap align-item-center gx-mt-3">
                                                <i className="icon usd-warning pending-color gx-fs-lg" />
                                                <div>
                                                    <h5 className="gx-m-0 gx-font-weight-medium gx-mb-sm-1">
                                                        {reporterName}
                                                    </h5>
                                                    <h5 className="gx-m-0 gx-font-weight-light gx-mb-sm-1">{reason}</h5>
                                                </div>
                                            </div>
                                            <div className="content-wrap gx-mb-3">
                                                <i className="icon usd-warning transparent gx-fs-lg" />
                                                <p className="gx-m-0 gx-font-weight-light gx-fs-11">
                                                    {getDateDifference(reportedOn)}
                                                </p>
                                            </div>
                                        </>
                                    );
                                })}
                            </Skeleton>
                        </Col>
                    )}
                    <Col
                        sm={24}
                        xs={24}
                        md={isReported && 12}
                        xl={isReported && 12}
                        lg={isReported && 12}
                        className="vertical-divider vertical-scroll"
                    >
                        <Post
                            postDetail={postData}
                            isLoading={postDataLoading}
                            commentCount={commentData?.filteredCount}
                            isHighlighted={CHAT_CONTENT_TYPE.POST == currentContent?.entityType}
                            setCurrentLanguage={() =>
                                setCurrentLanguage(
                                    currentLanguage === LANGUAGE_ID.ENGLISH ? LANGUAGE_ID.SPANISH : LANGUAGE_ID.ENGLISH
                                )
                            }
                            showAction={showAction}
                            onActionClick={onActionClick}
                        />
                        <Divider className="divider-background" />
                        <Skeleton active avatar loading={commentDataLoading || postDataLoading}>
                            {commentData?.list.map((comment, index) => (
                                <Comment
                                    commentDetail={comment}
                                    isHighlighted={currentContent?.entityId == comment?.id && index == 0}
                                    LanguageId={currentLanguage}
                                />
                            ))}
                        </Skeleton>
                        <Spin
                            indicator={antIcon}
                            spinning={commentDataFetching && !commentDataLoading}
                            className="width-100per gx-mt-3"
                        />
                        {remainingComment > 0 && (
                            <div
                                className="gx-fs-13 pp-text-blue cursor gx-mt-3"
                                onClick={onChangePageCount}
                            >{`${remainingComment} more ${remainingComment > 1 ? "comments..." : "comment..."}`}</div>
                        )}
                    </Col>
                </Row>
            </Drawer>
        </div>
    );
};

export default ContentDrawer;
