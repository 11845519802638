import { Select } from "antd";
import React from "react";
import { ACTIVITY_LIST } from "../../../constants/constant";

const Activity = (props) => {
    return (
        <Select {...props} allowClear getPopupContainer={(triggerNode) => triggerNode.parentElement}>
            {ACTIVITY_LIST?.map((data) => {
                return (
                    <Select.Option key={data?.id} value={data.id}>
                        {data?.name}
                    </Select.Option>
                );
            })}
        </Select>
    );
};
export default Activity;
