import React from "react";
import "../../../AddActivity/index.less";
import dateTimeService from "../../../../util/date-time.service";
import { changeFormat } from "../../../../util/date-time.service";

const EventCard = ({ data }) => {
    return (
        <div className="dateInfoContainer width100 cursor" key={data.key}>
            <p className="text-brand-primary-900 gx-fs-sm gx-m-0">{dateTimeService.formatDateToSpecificTZ(data?.startDate, "dddd")}, </p>
            <p className="text-brand-primary-900 gx-fs-sm">{dateTimeService.formatDateToSpecificTZ(data?.startDate, "MMM D YYYY")}</p>
            <p className="time-text gx-fs-sm gx-m-0">
                {dateTimeService.formatDateToSpecificTZ(data?.startDate, dateTimeService.DATE_TIME_FORMAT.TIME)}
            </p>
        </div>
    );
};

export default EventCard;

