import React, { useState } from "react";
import { AutoComplete, Input } from "antd";
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";

import "./index.less";

const GoogleAutoComplete = (props) => {
    const { placeholder = "Select location" } = props;
    let componentForm = {
        locality: "long_name",
        administrative_area_level_1: "long_name",
        postal_code: "long_name",
        country: "long_name",
        subpremise: "long_name",
        street_number: "long_name",
        route: "long_name"
    };
    const [address, setAddress] = useState("");
    const handleSelect = (address) => {
        setAddress(address);
        geocodeByAddress(address)
            .then((results) => props.onHandleSelect(parseAddress(results)))
            .then((latLng) => console.log("Success", latLng))
            .catch((error) => console.error("Error", error));
    };
    const onChange = (value) => {
        if (!value) {
            props.onHandleSelect(value);
        }
    };
    const parseAddress = ([
        { address_components: address = [], geometry: { location: { lat, lng } = {} } = {} } = {}
    ] = []) => {
        if (!address) {
            return;
        }

        let filteredAddress = {};
        for (let i = 0; i < address.length; i++) {
            let addressType = address[i].types[0];

            if (componentForm[addressType]) {
                let val = address[i][componentForm[addressType]];
                filteredAddress[addressType] = val;
            }
        }
        const newAddress = {
            city: filteredAddress.locality,
            zip: filteredAddress.postal_code,
            country: filteredAddress.country,
            state: filteredAddress.administrative_area_level_1,
            ...(filteredAddress?.subpremise && { subpremise: filteredAddress.subpremise }),
            ...(filteredAddress?.street_number && { street_number: filteredAddress.street_number }),
            ...(filteredAddress?.route && { route: filteredAddress.route }),
            ...(lat &&
                lng && {
                    latitude: typeof lat === "function" ? lat() : lat,
                    longitude: typeof lng === "function" ? lng() : lng
                })
        };
        return newAddress;
    };
    return (
        <>
            <PlacesAutocomplete
                key="google-autocomplete"
                value={address}
                onChange={(address) => {
                    setAddress(address);
                    onChange(address);
                }}
            >
                {({ getInputProps, suggestions }) => (
                    <AutoComplete
                        {...props}
                        options={suggestions.map((suggestion) => {
                            return { value: suggestion.description };
                        })}
                        onSelect={handleSelect}
                        className="google-auto-complete"
                    >
                        <Input
                            {...getInputProps({
                                placeholder: placeholder
                            })}
                            allowClear
                        />
                    </AutoComplete>
                )}
            </PlacesAutocomplete>
        </>
    );
};
export default GoogleAutoComplete;
