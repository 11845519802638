import React from "react";
import { Button, Space, Input } from "antd";
import { IN_APP_TAB_IDS } from "../../../../constants/constant";
import ViewActivity from "../../Activities/ViewActivity/Index";
import { ContentDrawer } from "../../Chat/ContentDrawer";
import ViewFreebie from "../../Freebie/ViewFreebie";
import ViewChildActivity from "../../Activity/View";

const Search = (props) => {
    return (
        <div className="gx-w-100 gx-mb-4">
            <p>Search</p>
            <Input {...props} />
        </div>
    );
};

const RenderDrawer = ({ onOpen, onClose, selectedData, selectedTab, showDrawer }) => {
    if (selectedTab == IN_APP_TAB_IDS.EVENT && showDrawer.visible) {
        return (
            <ViewActivity
                visible={onOpen}
                onClose={onClose}
                selectedActivity={selectedData}
                fromSearchActivity={true}
            />
        );
    } else if (selectedTab == IN_APP_TAB_IDS.FREEBIE && showDrawer.visible) {
        return <ViewFreebie visible={onOpen} onClose={onClose} selectedFreebie={selectedData} showActions={false} />;
    } else if (selectedTab == IN_APP_TAB_IDS.CHAT && showDrawer.visible) {
        return (
            <ContentDrawer
                visible={onOpen}
                onClose={onClose}
                showAction={false}
                selectedContent={selectedData != null ? selectedData : null}
            />
        );
    } else if (selectedTab == IN_APP_TAB_IDS.EXPLORE && showDrawer.visible) {
        return <ViewChildActivity visible={onOpen} selectedActivity={selectedData} onClose={onClose} />;
    } else {
        return <span></span>;
    }
};

export { Search, RenderDrawer };
