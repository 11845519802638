import React from "react";
import { Drawer, Space, Col, Row, Carousel, Divider } from "antd";

import "../Activities/ViewActivity/index.less";
import { CloseOutlined } from "@ant-design/icons";
import { renderAction } from "../Activities/helper";
import { getDateDifference } from "../../../util/date-time.service";
import LocationPin from "../../../assets/images/location-pin-colored.svg";
import utilService from "./../../../util/utils.service";

const _HEADLINE_KEY = {
    1: "headline",
    2: "headlineSpanish"
};

const _DESCRIPTION_KEY = {
    1: "description",
    2: "descriptionSpanish"
};

export default function ViewFreebie(props) {
    const { onClose, visible, isReported, selectedFreebie, onActionClick, showActions = true } = props;

    return (
        <div>
            <Drawer
                placement="right"
                closable={false}
                onClose={onClose}
                visible={visible}
                key="right"
                className={`custom-drawer custom-drawer-header ${isReported ? "report-drawer" : "activity-drawer"}`}
                title={
                    <Row justify="space-between" align="center" className="gx-m-0 gx-p-0">
                        {isReported && (
                            <Col sm={24} xs={24} md={12} xl={12} lg={12} className="gx-pb-3 gx-pt-3">
                                <p className=" gx-m-0 gx-p-0 noirProRegular gx-font-weight-medium gx-fs-lg">
                                    Reporters
                                </p>
                            </Col>
                        )}
                        <Col
                            sm={24}
                            xs={24}
                            md={isReported && 12}
                            xl={isReported && 12}
                            lg={isReported && 12}
                            className="header-vertical-divider gx-pb-3 gx-pt-3"
                        >
                            <Row justify="space-between" align="center" className="gx-m-0 gx-p-0">
                                <Col className="align-self-center">
                                    {selectedFreebie?.reportDetails ? (
                                        <p className=" gx-m-0 gx-p-0 noirProRegular gx-font-weight-medium gx-fs-lg">
                                            View Reported Freebie
                                        </p>
                                    ) : (
                                        <p className=" gx-m-0 gx-p-0 noirProRegular gx-font-weight-medium gx-fs-lg">
                                            View Freebie
                                        </p>
                                    )}
                                </Col>
                                <Col>
                                    <Space size="middle">
                                        <CloseOutlined onClick={onClose} />
                                    </Space>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }
            >
                <Row justify="space-between" align="center" className="gx-m-0 gx-p-0">
                    {isReported && (
                        <Col sm={24} xs={24} md={12} xl={12} lg={12} className="vertical-scroll">
                            {selectedFreebie?.reportDetails?.map((item, index) => {
                                let { reporterName, reason, reportedOn } = item;
                                return (
                                    <>
                                        {index > 0 && <Divider className="width-100per divider-style" />}
                                        <div className="content-wrap align-item-center gx-mt-3">
                                            <i className="icon usd-warning pending-color gx-fs-lg" />
                                            <div>
                                                <h5 className="gx-m-0 gx-font-weight-medium gx-mb-sm-1">
                                                    {reporterName}
                                                </h5>
                                                <h5 className="gx-m-0 gx-font-weight-light gx-mb-sm-1">{reason}</h5>
                                            </div>
                                        </div>
                                        <div className="content-wrap gx-mb-3">
                                            <i className="icon usd-warning transparent gx-fs-lg" />
                                            <p className="gx-m-0 gx-font-weight-light gx-fs-11">
                                                {getDateDifference(reportedOn)}
                                            </p>
                                        </div>
                                    </>
                                );
                            })}
                        </Col>
                    )}
                    <Col
                        sm={24}
                        xs={24}
                        md={isReported && 12}
                        xl={isReported && 12}
                        lg={isReported && 12}
                        className="vertical-divider vertical-scroll"
                    >
                        <div className="slider-container gx-mb-3">
                            <Carousel dotPosition="bottom" effect="fade" afterChange={(a, b, c) => console.log("")}>
                                {selectedFreebie?.media?.map((imageInfo) => {
                                    return (
                                        <div>
                                            <img
                                                style={{ height: "225px", borderRadius: "4px" }}
                                                src={imageInfo?.viewableLink}
                                            />
                                        </div>
                                    );
                                })}
                            </Carousel>
                        </div>
                        <Row justify="space-between" className="gx-ml-0 gx-pl-0 gx-pb-3 gx-pt-5">
                            <p className="gx-fs-xl text-brand-primary-900 gx-m-0 gx-pt-1 noirProRegular gx-font-weight-medium ">
                                {utilService.replaceNullWithPlaceholder(
                                    selectedFreebie?.content?.[_HEADLINE_KEY[selectedFreebie?.languageId ?? 1]]
                                )}
                            </p>
                            <Col sm={24} xs={24} md={6} xl={4} lg={5}>
                                {showActions && renderAction(selectedFreebie, onActionClick)}
                            </Col>
                        </Row>
                        <p className="description-text text-color-body gx-pt-2 gx-m-0 noirProRegular gx-font-weight-medium ">
                            {utilService.replaceNullWithPlaceholder(
                                selectedFreebie?.content?.[_DESCRIPTION_KEY[selectedFreebie?.languageId ?? 1]]
                            )}
                        </p>
                        <Row className="gx-ml-0 gx-pl-0 gx-pt-3 gx-m-0">
                            <p className="description-text text-color-body noirProMedium">Posted on</p>
                            <p className="description-text gx-ml-2 text-color-body noirProRegular gx-font-weight-medium ">
                                {" "}
                                {getDateDifference(selectedFreebie?.contentCreatedOn)}
                            </p>
                        </Row>
                        <p className="gx-fs-sm gx-mt-2 text-color-body noirProRegular">Category</p>
                        <Space size="small">
                            <div className="icon20">
                                <img src={selectedFreebie?.categoryUrl} />
                            </div>
                            <p className="text-color-body gx-m-0 noirProRegular gx-font-weight-normal gx-fs-md">
                                {selectedFreebie?.categoryName}
                            </p>
                        </Space>
                        <p className="gx-fs-sm gx-mt-4 text-color-body noirProRegular">Neighborhood</p>
                        <Space size="small">
                            <div className="icon20">
                                <img src={LocationPin} />
                            </div>
                            <p className="text-color-body gx-m-0 noirProRegular gx-font-weight-normal gx-fs-md">
                                {selectedFreebie?.neighborhoodName}
                            </p>
                        </Space>
                    </Col>
                </Row>
            </Drawer>
        </div>
    );
}
