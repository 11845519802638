import React, { useState } from "react";
import { Space, Col, Row, Carousel, Skeleton, Typography } from "antd";

import GamificationBadge from "./GamificationBadge";
import { renderAction } from "../../Activities/helper";
import PinIcon from "../../../../assets/images/pin.svg";
import CommentIcon from "../../../../assets/images/comment.svg";
import UserIllustration from "../../../../assets/images/useravatar.svg";
import GlobeIllustration from "../../../../assets/images/globe_grey.svg";
import { getDateDifference } from "../../../../util/date-time.service";

const NO_OF_ROWS = 4;
const Post = ({
    postDetail,
    isLoading,
    commentCount,
    isHighlighted = false,
    setCurrentLanguage,
    onActionClick,
    showAction
}) => {
    const { Paragraph } = Typography;

    return (
        <Skeleton active avatar loading={isLoading} className="gx-pt-3">
            <Row align="middle" justify="space-between" className="gx-m-0 gx-pt-3">
                <Col sm={4} xs={4} md={4} xl={4} lg={4} className="gx-p-0">
                    {postDetail?.profilePictureUrl ? (
                        <img className="image-style" src={postDetail?.profilePictureUrl} />
                    ) : (
                        <img className="icon-size-40" src={UserIllustration} />
                    )}
                </Col>
                <Col sm={17} xs={17} md={17} xl={17} lg={17} className="gx-p-0">
                    <Row>
                        <Space className="gx-mb-2">
                            <h5 className="gx-font-weight-medium gx-m-0">{postDetail?.userName}</h5>
                            <GamificationBadge badgeList={postDetail?.badges?.badges} />
                        </Space>
                    </Row>
                    <Row>
                        <Space>
                            <h6 className="gx-m-0 gx-font-weight-light gx-text-gray">
                                {getDateDifference(postDetail?.createdOn)}
                            </h6>
                            <Space>
                                <img className="icon-size-14" src={PinIcon} />
                                <h6 className="gx-m-0 gx-font-weight-light gx-text-gray">
                                    {postDetail?.neighborhoodName}
                                </h6>
                            </Space>
                        </Space>
                    </Row>
                </Col>

                <Col sm={2} xs={2} md={2} xl={2} lg={2} className="gx-m-0 gx-p-0">
                    {showAction && renderAction(postDetail, onActionClick)}
                </Col>
            </Row>
            {postDetail?.media.length > 0 ? (
                <Col className="slider-container">
                    <Carousel dotPosition="bottom" effect="fade">
                        {postDetail?.media?.map((media) => {
                            return (
                                <div>
                                    <img className="image-container" src={media?.viewableLink} />
                                </div>
                            );
                        })}
                    </Carousel>
                </Col>
            ) : null}
            <Col className="gx-mt-2" style={{ background: isHighlighted == true ? "#fffae6" : "transparent" }}>
                <h5 className="gx-font-weight-medium gx-text-dark-blue">{postDetail?.title}</h5>
                <Paragraph
                    ellipsis={{ rows: NO_OF_ROWS, expandable: true, symbol: "more" }}
                    className="gx-font-weight-light gx-text-light-gray gx-fs-md"
                >
                    {postDetail?.content}
                </Paragraph>
            </Col>
            <Row align="center" justify="space-between" className="gx-m-0">
                <Space className="align-baseline">
                    <img className="icon-size-14" src={CommentIcon} />
                    <h6 className="gx-font-weight-light gx-text-light-gray gx-m-0">{commentCount}</h6>
                </Space>
                <Space className="align-baseline cursor" onClick={setCurrentLanguage}>
                    <img className="icon-size-14" src={GlobeIllustration} />
                    <h6 className="gx-font-weight-light gx-text-light-gray gx-m-0">Translate</h6>
                </Space>
            </Row>
        </Skeleton>
    );
};

export default Post;
