import React from "react";
import { Space, Col, Row, Typography } from "antd";

import GamificationBadge from "./GamificationBadge";
import PinIcon from "../../../../assets/images/pin.svg";
import { getDateDifference } from "../../../../util/date-time.service";
import UserIllustration from "../../../../assets/images/useravatar.svg";

const NO_OF_ROWS = 4;

const SubCommentReplies = ({ subRepliesDetail }) => {
    const { Paragraph } = Typography;
    return (
        <Row align="center" justify="space-between" className="gx-m-0 gx-pt-3">
            <Col sm={4} xs={4} md={4} xl={4} lg={4} className="gx-p-0">
                {subRepliesDetail?.profilePictureUrl ? (
                    <img className="image-style" src={subRepliesDetail?.profilePictureUrl} />
                ) : (
                    <img className="icon-size-40" src={UserIllustration} />
                )}
            </Col>
            <Col sm={19} xs={19} md={19} xl={19} lg={19} className="gx-p-0">
                <Row className="gx-p-0 gx-m-0">
                    <Space className="gx-mb-2">
                        <h5 className="gx-font-weight-medium gx-m-0">{subRepliesDetail?.userName}</h5>
                        <GamificationBadge badgeList={subRepliesDetail?.badges?.badges} />
                        <Space>
                            <img className="icon-size-14" src={PinIcon} />
                            <h6 className="gx-m-0 gx-font-weight-light gx-text-gray">
                                {subRepliesDetail?.neighborhoodName}
                            </h6>
                        </Space>
                    </Space>
                </Row>
                <Row className="gx-p-0 gx-m-0">
                    <Paragraph
                        ellipsis={{ rows: NO_OF_ROWS, expandable: true, symbol: "more" }}
                        className="gx-font-weight-light gx-text-light-gray gx-fs-md gx-mb-2"
                    >
                        {subRepliesDetail?.content}
                    </Paragraph>
                    <h6 className="gx-m-0 gx-font-weight-light gx-text-gray width-100per">
                        {getDateDifference(subRepliesDetail?.createdOn)}
                    </h6>
                </Row>
            </Col>
        </Row>
    );
};

export default SubCommentReplies;
