import React from "react";
import {Select} from "antd";
import {useQuery} from "react-query";

import {LookUpApiService} from "../../../ApiServices";
import notificationService from "../../../util/notification.service";
import {
  API_STORAGE_KEY,
  TOPIC_LOOKUP_TYPE,
} from "./../../../constants/constant";

const TopicLookup = (lookupProps) => {
  const {
    type = TOPIC_LOOKUP_TYPE.ALL_NEIGHORHOOD,
    selectedTopic,
    ...props
  } = lookupProps;
  const getTopics = async () => {
    const {ok, data, response} = await LookUpApiService.getTopic(type);
    if (ok) {
      return data;
    } else {
      notificationService.error(response.message);
      throw new Error(response.message);
    }
  };

  const {data: topics = [], isLoading} = useQuery(
    type === TOPIC_LOOKUP_TYPE.ALL_NEIGHORHOOD
      ? API_STORAGE_KEY.TOPIC_LOOKUP
      : API_STORAGE_KEY.CHAT_TOPICS,
    getTopics
  );

  return (
    <>
      <Select
        {...props}
        showSearch
        filterOption={(input, option) => {
          return option.props.children
            .toLowerCase()
            .includes(input.toLowerCase());
        }}
        style={{width: "150px"}}
        allowClear
        loading={isLoading}
        disabled={isLoading}
        getPopupContainer={(triggerNode) => triggerNode.parentElement}
      >
        {topics
          ?.filter((item) => item?.id !== selectedTopic?.id)
          ?.map((data) => {
            return (
              <Select.Option key={data?.id} value={data.id}>
                {data?.name}
              </Select.Option>
            );
          })}
      </Select>
    </>
  );
};
export default TopicLookup;
